html {
  font-size : 14px;
  height : 100%;
  letter-spacing : 0.01rem;
}

html body {
  height : 100%;
  background-color : #F8F8F8;
  direction : ltr;
}

html body p {
  line-height : 1.5rem;
}

html .content {
  padding : 0;
  position : relative;
  transition : 300ms ease all;
  -webkit-backface-visibility : hidden;
          backface-visibility : hidden;
  min-height : calc(100% - 3.35rem);
  margin-left : 260px;
}

html .content.app-content {
  padding : calc(2rem + 4.45rem + 1.3rem) 2rem 0;
}

html .content.app-content.show-overlay .content-overlay {
  z-index : 10;
  opacity : 1;
}

html .content.app-content.show-overlay .content-overlay ~ .header-navbar-shadow {
  display : none;
}

html .content.app-content .content-overlay {
  position : fixed;
  opacity : 0;
  width : 100%;
  height : 100%;
  top : 0;
  left : 0;
  right : 0;
  bottom : 0;
  background-color : rgba(34, 41, 47, 0.5);
  cursor : pointer;
  transition : all 0.7s, z-index 0s;
  z-index : -1;
}

html .content.app-content .content-area-wrapper {
  display : flex;
  position : relative;
  overflow : hidden;
}

html .content.app-content .content-area-wrapper .content-wrapper, html .content.app-content .content-area-wrapper .content-body {
  height : 100%;
}

html .content .body-content-overlay {
  top : 0;
  left : 0;
  right : 0;
  bottom : 0;
  position : absolute;
  display : block;
  z-index : 4;
  visibility : hidden;
  opacity : 0;
  transition : all 0.3s ease;
}

html .content .body-content-overlay.show {
  visibility : visible;
  transition : all 0.3s ease;
  opacity : 1;
  background-color : rgba(34, 41, 47, 0.2);
  border-radius : 0.1785rem;
}

html .content .content-wrapper .content-header-title {
  font-weight : 500;
  color : #636363;
  padding-right : 1rem;
  border-right : 1px solid #D6DCE1;
}

html .content .content-wrapper .content-header-right .dropdown-toggle::after {
  display : none;
}

html .content .content-wrapper .content-header-right .dropdown-toggle i, html .content .content-wrapper .content-header-right .dropdown-toggle svg {
  margin-right : 0;
}

html .content .content-wrapper .content-header-right .btn-icon {
  padding : 0.8rem;
}

html .content .content-wrapper .content-header-right .btn-icon i, html .content .content-wrapper .content-header-right .btn-icon svg {
  height : 1rem;
  width : 1rem;
}

html .navbar-floating.footer-static .app-content .content-area-wrapper, html .navbar-floating.footer-static .app-content .kanban-wrapper {
  height : calc(
  100vh -
  calc(
  calc(2rem * 1) + 4.45rem + 3.35rem + 1.3rem + 0rem
  ));
  height : calc(
  var(--vh, 1vh) * 100 -
  calc(
  calc(2rem * 1) + 4.45rem + 3.35rem + 1.3rem + 0rem
  ));
}

@media (max-width: 575.98px) {
  html .navbar-floating.footer-static .app-content .content-area-wrapper, html .navbar-floating.footer-static .app-content .kanban-wrapper {
    height : calc(
    100vh -
    calc(
    calc(calc(2rem * 1) - calc(0.8rem * 1)) + 4.45rem + 3.35rem +
    1.3rem + 0rem
    ));
    height : calc(
    var(--vh, 1vh) * 100 -
    calc(
    calc(calc(2rem * 1) - calc(0.8rem * 1)) + 4.45rem + 3.35rem +
    1.3rem + 0rem
    ));
  }
}

html .navbar-floating.footer-hidden .app-content .content-area-wrapper, html .navbar-floating.footer-hidden .app-content .kanban-wrapper {
  height : calc(
  100vh -
  calc(
  calc(2rem * 2) + 4.45rem + 0rem + 1.3rem + 0rem
  ));
  height : calc(
  var(--vh, 1vh) * 100 -
  calc(
  calc(2rem * 2) + 4.45rem + 0rem + 1.3rem + 0rem
  ));
}

@media (max-width: 575.98px) {
  html .navbar-floating.footer-hidden .app-content .content-area-wrapper, html .navbar-floating.footer-hidden .app-content .kanban-wrapper {
    height : calc(
    100vh -
    calc(
    calc(calc(2rem * 2) - calc(0.8rem * 2)) + 4.45rem + 0rem +
    1.3rem + 0rem
    ));
    height : calc(
    var(--vh, 1vh) * 100 -
    calc(
    calc(calc(2rem * 2) - calc(0.8rem * 2)) + 4.45rem + 0rem +
    1.3rem + 0rem
    ));
  }
}

html .navbar-floating.footer-fixed .app-content .content-area-wrapper, html .navbar-floating.footer-fixed .app-content .kanban-wrapper {
  height : calc(
  100vh -
  calc(
  calc(2rem * 2) + 4.45rem + 3.35rem + 1.3rem + 0rem
  ));
  height : calc(
  var(--vh, 1vh) * 100 -
  calc(
  calc(2rem * 2) + 4.45rem + 3.35rem + 1.3rem + 0rem
  ));
}

@media (max-width: 575.98px) {
  html .navbar-floating.footer-fixed .app-content .content-area-wrapper, html .navbar-floating.footer-fixed .app-content .kanban-wrapper {
    height : calc(
    100vh -
    calc(
    calc(calc(2rem * 2) - calc(0.8rem * 2)) + 4.45rem + 3.35rem +
    1.3rem + 0rem
    ));
    height : calc(
    var(--vh, 1vh) * 100 -
    calc(
    calc(calc(2rem * 2) - calc(0.8rem * 2)) + 4.45rem + 3.35rem +
    1.3rem + 0rem
    ));
  }
}

html .navbar-hidden .app-content {
  padding : 2rem 2rem 0 2rem !important;
}

html .navbar-hidden.footer-static .app-content .content-area-wrapper, html .navbar-hidden.footer-static .app-content .kanban-wrapper {
  height : calc(
  100vh -
  calc(
  calc(2rem * 1) + 0rem + 3.35rem + 0rem + 0rem
  ));
  height : calc(
  var(--vh, 1vh) * 100 -
  calc(
  calc(2rem * 1) + 0rem + 3.35rem + 0rem + 0rem
  ));
}

@media (max-width: 575.98px) {
  html .navbar-hidden.footer-static .app-content .content-area-wrapper, html .navbar-hidden.footer-static .app-content .kanban-wrapper {
    height : calc(
    100vh -
    calc(
    calc(calc(2rem * 1) - calc(0.8rem * 1)) + 0rem + 3.35rem +
    0rem + 0rem
    ));
    height : calc(
    var(--vh, 1vh) * 100 -
    calc(
    calc(calc(2rem * 1) - calc(0.8rem * 1)) + 0rem + 3.35rem +
    0rem + 0rem
    ));
  }
}

html .navbar-hidden.footer-hidden .app-content .content-area-wrapper, html .navbar-hidden.footer-hidden .app-content .kanban-wrapper {
  height : calc(
  100vh -
  calc(
  calc(2rem * 2) + 0rem + 0rem + 0rem + 0rem
  ));
  height : calc(
  var(--vh, 1vh) * 100 -
  calc(
  calc(2rem * 2) + 0rem + 0rem + 0rem + 0rem
  ));
}

@media (max-width: 575.98px) {
  html .navbar-hidden.footer-hidden .app-content .content-area-wrapper, html .navbar-hidden.footer-hidden .app-content .kanban-wrapper {
    height : calc(
    100vh -
    calc(
    calc(calc(2rem * 2) - calc(0.8rem * 2)) + 0rem + 0rem +
    0rem + 0rem
    ));
    height : calc(
    var(--vh, 1vh) * 100 -
    calc(
    calc(calc(2rem * 2) - calc(0.8rem * 2)) + 0rem + 0rem +
    0rem + 0rem
    ));
  }
}

html .navbar-hidden.footer-fixed .app-content .content-area-wrapper, html .navbar-hidden.footer-fixed .app-content .kanban-wrapper {
  height : calc(
  100vh -
  calc(
  calc(2rem * 2) + 0rem + 3.35rem + 0rem + 0rem
  ));
  height : calc(
  var(--vh, 1vh) * 100 -
  calc(
  calc(2rem * 2) + 0rem + 3.35rem + 0rem + 0rem
  ));
}

@media (max-width: 575.98px) {
  html .navbar-hidden.footer-fixed .app-content .content-area-wrapper, html .navbar-hidden.footer-fixed .app-content .kanban-wrapper {
    height : calc(
    100vh -
    calc(
    calc(calc(2rem * 2) - calc(0.8rem * 2)) + 0rem + 3.35rem +
    0rem + 0rem
    ));
    height : calc(
    var(--vh, 1vh) * 100 -
    calc(
    calc(calc(2rem * 2) - calc(0.8rem * 2)) + 0rem + 3.35rem +
    0rem + 0rem
    ));
  }
}

html .navbar-static .main-menu {
  top : 0;
}

html .navbar-static .content {
  min-height : calc(100% - calc(4.45rem + 3.35rem));
}

html .navbar-static .app-content {
  padding : 2rem 2rem 0 2rem;
}

html .navbar-static.footer-static .app-content .content-area-wrapper, html .navbar-static.footer-static .app-content .kanban-wrapper {
  height : calc(
  100vh -
  calc(
  calc(2rem * 1) + 4.45rem + 3.35rem + 0rem + 0rem
  ));
  height : calc(
  var(--vh, 1vh) * 100 -
  calc(
  calc(2rem * 1) + 4.45rem + 3.35rem + 0rem + 0rem
  ));
}

@media (max-width: 575.98px) {
  html .navbar-static.footer-static .app-content .content-area-wrapper, html .navbar-static.footer-static .app-content .kanban-wrapper {
    height : calc(
    100vh -
    calc(
    calc(calc(2rem * 1) - calc(0.8rem * 1)) + 4.45rem + 3.35rem +
    0rem + 0rem
    ));
    height : calc(
    var(--vh, 1vh) * 100 -
    calc(
    calc(calc(2rem * 1) - calc(0.8rem * 1)) + 4.45rem + 3.35rem +
    0rem + 0rem
    ));
  }
}

html .navbar-static.footer-hidden .app-content .content-area-wrapper, html .navbar-static.footer-hidden .app-content .kanban-wrapper {
  height : calc(
  100vh -
  calc(
  calc(2rem * 2) + 4.45rem + 0rem + 0rem + 0rem
  ));
  height : calc(
  var(--vh, 1vh) * 100 -
  calc(
  calc(2rem * 2) + 4.45rem + 0rem + 0rem + 0rem
  ));
}

@media (max-width: 575.98px) {
  html .navbar-static.footer-hidden .app-content .content-area-wrapper, html .navbar-static.footer-hidden .app-content .kanban-wrapper {
    height : calc(
    100vh -
    calc(
    calc(calc(2rem * 2) - calc(0.8rem * 2)) + 4.45rem + 0rem +
    0rem + 0rem
    ));
    height : calc(
    var(--vh, 1vh) * 100 -
    calc(
    calc(calc(2rem * 2) - calc(0.8rem * 2)) + 4.45rem + 0rem +
    0rem + 0rem
    ));
  }
}

html .navbar-static.footer-fixed .app-content .content-area-wrapper, html .navbar-static.footer-fixed .app-content .kanban-wrapper {
  height : calc(
  100vh -
  calc(
  calc(2rem * 2) + 4.45rem + 3.35rem + 0rem + 0rem
  ));
  height : calc(
  var(--vh, 1vh) * 100 -
  calc(
  calc(2rem * 2) + 4.45rem + 3.35rem + 0rem + 0rem
  ));
}

@media (max-width: 575.98px) {
  html .navbar-static.footer-fixed .app-content .content-area-wrapper, html .navbar-static.footer-fixed .app-content .kanban-wrapper {
    height : calc(
    100vh -
    calc(
    calc(calc(2rem * 2) - calc(0.8rem * 2)) + 4.45rem + 3.35rem +
    0rem + 0rem
    ));
    height : calc(
    var(--vh, 1vh) * 100 -
    calc(
    calc(calc(2rem * 2) - calc(0.8rem * 2)) + 4.45rem + 3.35rem +
    0rem + 0rem
    ));
  }
}

html .navbar-sticky .app-content {
  padding : calc(2rem + 4.45rem) 2rem 0 2rem;
}

html .navbar-sticky.footer-static .app-content .content-area-wrapper, html .navbar-sticky.footer-static .app-content .kanban-wrapper {
  height : calc(
  100vh -
  calc(
  calc(2rem * 1) + 4.45rem + 3.35rem + 0rem + 0rem
  ));
  height : calc(
  var(--vh, 1vh) * 100 -
  calc(
  calc(2rem * 1) + 4.45rem + 3.35rem + 0rem + 0rem
  ));
}

@media (max-width: 575.98px) {
  html .navbar-sticky.footer-static .app-content .content-area-wrapper, html .navbar-sticky.footer-static .app-content .kanban-wrapper {
    height : calc(
    100vh -
    calc(
    calc(calc(2rem * 1) - calc(0.8rem * 1)) + 4.45rem + 3.35rem +
    0rem + 0rem
    ));
    height : calc(
    var(--vh, 1vh) * 100 -
    calc(
    calc(calc(2rem * 1) - calc(0.8rem * 1)) + 4.45rem + 3.35rem +
    0rem + 0rem
    ));
  }
}

html .navbar-sticky.footer-hidden .app-content .content-area-wrapper, html .navbar-sticky.footer-hidden .app-content .kanban-wrapper {
  height : calc(
  100vh -
  calc(
  calc(2rem * 2) + 4.45rem + 0rem + 0rem + 0rem
  ));
  height : calc(
  var(--vh, 1vh) * 100 -
  calc(
  calc(2rem * 2) + 4.45rem + 0rem + 0rem + 0rem
  ));
}

@media (max-width: 575.98px) {
  html .navbar-sticky.footer-hidden .app-content .content-area-wrapper, html .navbar-sticky.footer-hidden .app-content .kanban-wrapper {
    height : calc(
    100vh -
    calc(
    calc(calc(2rem * 2) - calc(0.8rem * 2)) + 4.45rem + 0rem +
    0rem + 0rem
    ));
    height : calc(
    var(--vh, 1vh) * 100 -
    calc(
    calc(calc(2rem * 2) - calc(0.8rem * 2)) + 4.45rem + 0rem +
    0rem + 0rem
    ));
  }
}

html .navbar-sticky.footer-fixed .app-content .content-area-wrapper, html .navbar-sticky.footer-fixed .app-content .kanban-wrapper {
  height : calc(
  100vh -
  calc(
  calc(2rem * 2) + 4.45rem + 3.35rem + 0rem + 0rem
  ));
  height : calc(
  var(--vh, 1vh) * 100 -
  calc(
  calc(2rem * 2) + 4.45rem + 3.35rem + 0rem + 0rem
  ));
}

@media (max-width: 575.98px) {
  html .navbar-sticky.footer-fixed .app-content .content-area-wrapper, html .navbar-sticky.footer-fixed .app-content .kanban-wrapper {
    height : calc(
    100vh -
    calc(
    calc(calc(2rem * 2) - calc(0.8rem * 2)) + 4.45rem + 3.35rem +
    0rem + 0rem
    ));
    height : calc(
    var(--vh, 1vh) * 100 -
    calc(
    calc(calc(2rem * 2) - calc(0.8rem * 2)) + 4.45rem + 3.35rem +
    0rem + 0rem
    ));
  }
}

html .navbar-static .nav.header-navbar .navbar-container, html .navbar-sticky .nav.header-navbar .navbar-container {
  padding-left : 1.6rem;
  padding-right : 1.6rem;
}

html .navbar-static .nav.header-navbar .navbar-container .search-input .search-list.show, html .navbar-sticky .nav.header-navbar .navbar-container .search-input .search-list.show {
  width : 98%;
  left : 1%;
}

html .footer-fixed .content.app-content {
  padding-bottom : 3.35rem !important;
}

html [data-col='1-column'] .header-navbar {
  width : 100%;
  left : 0 !important;
}

html [data-col='1-column'] .header-navbar.floating-nav {
  width : calc(
  100vw - (100vw - 100%) - calc(2rem * 2)) !important;
}

html [data-col='1-column'] .content, html [data-col='1-column'] .footer {
  margin-left : 0 !important;
}

html [data-col='1-column'].horizontal-layout .header-navbar {
  width : 100% !important;
}

html [data-col='1-column'].horizontal-layout .app-content {
  padding : calc(2rem + 4.45rem + 1.3rem) 2rem 0 2rem !important;
}

html [data-col='1-column'].horizontal-layout.footer-fixed .app-content {
  padding : calc(2rem + 4.45rem + 1.3rem) 2rem 3.35rem 2rem !important;
}

html .blank-page .content {
  margin-left : 0;
}

html .blank-page .content.app-content {
  overflow : overlay;
  overflow-x : hidden;
  padding : 0 !important;
  transition : none;
}

html .blank-page .content.app-content .header-navbar-shadow {
  display : none;
}

html .blank-page .content-wrapper {
  padding : 0 !important;
}

html .blank-page .content-wrapper .flexbox-container {
  display : flex;
  align-items : center;
  height : 100vh;
  height : calc(var(--vh, 1vh) * 100);
  justify-content : center;
}

html .pace .pace-progress {
  background : #7367F0;
}

.app-content.center-layout {
  overflow : hidden;
}

@media (min-width: 992px) {
  body .content-right {
    width : calc(100vw - (100vw - 100%) - 260px);
    float : right;
  }
  body .content-left {
    width : calc(100vw - (100vw - 100%) - 260px);
    float : left;
  }
  body .content-detached {
    width : 100%;
  }
  body .content-detached.content-right {
    float : right;
    margin-left : -260px;
  }
  body .content-detached.content-right .content-body {
    margin-left : calc(260px + 2rem);
  }
  body .content-detached.content-left {
    float : left;
    margin-right : -260px;
  }
  body .content-detached.content-left .content-body {
    margin-right : calc(260px + 2rem);
  }
  .sidebar-right.sidebar-sticky {
    float : right !important;
    margin-left : -260px;
    width : 260px !important;
    margin-top : 5.7rem;
  }
}

@media (max-width: 575.98px) {
  html body .app-content {
    padding : calc(2rem - 0.8rem + 4.45rem + 1.3rem) calc(2rem - 0.8rem) 0 calc(2rem - 0.8rem) !important;
  }
  html body.navbar-static .app-content {
    padding : calc(2rem - 0.8rem) calc(2rem - 0.8rem) 0 calc(2rem - 0.8rem) !important;
  }
  html body.navbar-sticky .app-content {
    padding : calc(2rem - 0.8rem + 4.45rem) calc(2rem - 0.8rem) 0 calc(2rem - 0.8rem) !important;
  }
  html body.navbar-hidden .app-content {
    padding : calc(2rem - 0.8rem) calc(2rem - 0.8rem) 0 calc(2rem - 0.8rem) !important;
  }
}

@media (max-width: 575.98px) {
  .navbar-static .app-content .header-navbar .navbar-container, .navbar-sticky .app-content .header-navbar .navbar-container {
    padding-left : 1rem;
    padding-right : 0.5rem;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  html.full-screen {
    width : 100%;
  }
  html.full-screen .content.app-content {
    height : 100%;
    overflow : scroll;
    overflow-x : hidden;
  }
}

.sidebar {
  position : relative;
  width : 100%;
  height : 100%;
}

@media (min-width: 992px) {
  .sidebar {
    vertical-align : top;
  }
}

.sidebar-fixed {
  position : fixed;
  height : 100%;
  overflow : scroll;
}

.sidenav-overlay {
  position : fixed;
  top : 0;
  left : 0;
  right : 0;
  height : 120vh;
  background-color : rgba(34, 41, 47, 0.5);
  z-index : 997;
  display : block;
  visibility : hidden;
  opacity : 0;
  transition : all 0.5s ease;
}

.sidenav-overlay.show {
  opacity : 1;
  visibility : visible;
  transition : all 0.5s ease;
}

.drag-target {
  height : 100%;
  width : 40px;
  position : fixed;
  top : 0;
  left : -10px;
  z-index : 1036;
}

.sidebar-detached .sidebar {
  width : 260px;
}

@media (min-width: 992px) {
  .sidebar-left {
    float : left;
  }
  .sidebar-right {
    float : right;
  }
}

@media (max-width: 991.98px) {
  .sidebar-detached .sidebar {
    width : 100%;
  }
}

footer.footer {
  padding : 0.8rem 2rem;
  min-height : 3.35rem;
  transition : 300ms ease all;
}

footer.footer span i, footer.footer span svg {
  height : 1.51rem;
  width : 1.51rem;
  font-size : 1.51rem;
  margin-left : 0.5rem;
  color : #EA5455;
}

footer.footer .scroll-top {
  padding : 0.81rem 0.83rem;
}

footer.footer-shadow {
  box-shadow : 0 4px 24px 0 rgba(34, 41, 47, 0.1);
}

footer.footer-border {
  border-top : 1px solid #EBE9F1;
}

.footer-fixed footer {
  position : fixed !important;
  z-index : 1030;
  right : 0;
  left : 0;
  bottom : 0;
}

.footer-fixed footer.footer-light {
  background : #FFFFFF;
  box-shadow : 0 4px 24px 0 rgba(34, 41, 47, 0.1);
}

.footer-fixed footer.footer-dark {
  background : #B8C2CC;
  color : #FFFFFF;
}

.footer-hidden .app-content {
  padding-bottom : 0.1px !important;
}

.scroll-top {
  position : fixed;
  bottom : 5%;
  right : 30px;
  display : none;
  z-index : 99;
}

.main-menu {
  z-index : 1031;
  position : absolute;
  display : table-cell;
  height : 100%;
  overflow : hidden;
}

.main-menu.menu-light {
  color : #6E6B7B;
  background : #FFFFFF;
}

.main-menu.menu-light .navigation {
  background : #FFFFFF;
}

.main-menu.menu-light .navigation .navigation-header {
  color : #A6A4B0;
  margin : calc(2rem + 0.286rem) 0 0.8rem 2.2rem;
  padding : 0;
  line-height : 1.5;
  letter-spacing : 0.01rem;
}

.main-menu.menu-light .navigation .navigation-header span {
  font-weight : 500;
}

.main-menu.menu-light .navigation li.has-sub > a {
  transition : all 0.5s ease, background 0s, color 0s;
}

.main-menu.menu-light .navigation li a {
  color : #625F6E;
  padding : 10px 15px 10px 15px;
  line-height : 1.45;
}

.main-menu.menu-light .navigation li a span.menu-sub-title {
  color : #BDBBC4;
}

.main-menu.menu-light .navigation li a > * {
  transition : transform 0.25s ease;
}

.main-menu.menu-light .navigation li.nav-item a:hover > * {
  transition : transform 0.25s ease;
  transform : translateX(5px);
}

.main-menu.menu-light .navigation li.nav-item ul.menu-content li a:hover > * {
  transition : transform 0.25s ease;
  transform : translateX(5px);
}

.main-menu.menu-light .navigation > li > a {
  margin : 0 15px;
}

.main-menu.menu-light .navigation > li > a .label {
  margin-top : 4px;
  margin-right : 5px;
}

.main-menu.menu-light .navigation > li > a i, .main-menu.menu-light .navigation > li > a svg {
  position : relative;
  top : 1px;
}

.main-menu.menu-light .navigation > li > ul li:first-of-type {
  margin-top : 7px !important;
}

.main-menu.menu-light .navigation > li > ul li:not(.has-sub) {
  margin : 0 15px;
}

.main-menu.menu-light .navigation > li > ul li.has-sub > a {
  margin : 0 15px;
}

.main-menu.menu-light .navigation > li > ul li.has-sub > ul > li {
  margin : 0 15px;
}

.main-menu.menu-light .navigation > li.open:not(.menu-item-closing) > a, .main-menu.menu-light .navigation > li.sidebar-group-active > a {
  color : #565360;
  background : whitesmoke;
  border-radius : 6px;
}

.main-menu.menu-light .navigation > li:not(.open) > ul {
  display : none;
}

.main-menu.menu-light .navigation > li:not(.open) > ul .active {
  background : transparent;
  box-shadow : none;
}

.main-menu.menu-light .navigation > li.active {
  z-index : 1;
}

.main-menu.menu-light .navigation > li.active > a {
  background : linear-gradient(118deg, #7367F0, rgba(115, 103, 240, 0.7));
  box-shadow : 0 0 10px 1px rgba(115, 103, 240, 0.7);
  color : #FFFFFF;
  font-weight : 400;
  border-radius : 4px;
}

.main-menu.menu-light .navigation > li .active {
  background : #F0F0F0;
}

.main-menu.menu-light .navigation > li .active > a {
  color : #7367F0;
  margin-bottom : 0;
}

.main-menu.menu-light .navigation > li .active .hover > a, .main-menu.menu-light .navigation > li .active :hover > a {
  background : whitesmoke;
}

.main-menu.menu-light .navigation > li ul {
  padding : 0;
  margin : 0;
}

.main-menu.menu-light .navigation > li ul li {
  color : #6E6B7B;
  background : transparent;
}

.main-menu.menu-light .navigation > li ul li > a {
  padding : 10px 15px 10px 20px;
}

.main-menu.menu-light .navigation > li ul li ul a {
  padding : 10px 15px 10px 53px;
}

.main-menu.menu-light .navigation > li ul .has-sub:not(.open) > ul {
  display : none;
}

.main-menu.menu-light .navigation > li ul .has-sub:not(.open) > ul .active {
  background : transparent;
  box-shadow : none;
}

.main-menu.menu-light .navigation > li ul .open > a, .main-menu.menu-light .navigation > li ul .sidebar-group-active > a {
  color : #6E6B7B;
  background : whitesmoke;
  border-radius : 6px;
}

.main-menu.menu-light .navigation > li ul .open > ul, .main-menu.menu-light .navigation > li ul .sidebar-group-active > ul {
  display : block;
}

.main-menu.menu-light .navigation > li ul .open > ul .open > ul, .main-menu.menu-light .navigation > li ul .sidebar-group-active > ul .open > ul {
  display : block;
}

.main-menu.menu-light .navigation > li ul .hover > a, .main-menu.menu-light .navigation > li ul:hover > a {
  color : #787486;
}

.main-menu.menu-light .navigation > li ul .active {
  background : linear-gradient(118deg, #7367F0, rgba(115, 103, 240, 0.7));
  box-shadow : 0 0 10px 1px rgba(115, 103, 240, 0.7);
  border-radius : 4px;
  z-index : 1;
}

.main-menu.menu-light .navigation > li ul .active > a {
  color : #FFFFFF;
}

.main-menu.menu-dark {
  color : #DCDCDC;
  background : #10163A;
}

.main-menu.menu-dark .navigation {
  background : #10163A;
}

.main-menu.menu-dark .navigation .navigation-header {
  color : white;
  margin : calc(2rem + 0.286rem) 0 0.8rem 2.2rem;
  padding : 0;
  line-height : 1.5;
  letter-spacing : 0.01rem;
}

.main-menu.menu-dark .navigation .navigation-header span {
  font-weight : 500;
}

.main-menu.menu-dark .navigation li.has-sub > a {
  transition : all 0.5s ease, background 0s, color 0s;
}

.main-menu.menu-dark .navigation li a {
  color : #D0D0D0;
  padding : 10px 15px 10px 15px;
  line-height : 1.45;
}

.main-menu.menu-dark .navigation li a span.menu-sub-title {
  color : white;
}

.main-menu.menu-dark .navigation li a > * {
  transition : transform 0.25s ease;
}

.main-menu.menu-dark .navigation li.nav-item a:hover > * {
  transition : transform 0.25s ease;
  transform : translateX(5px);
}

.main-menu.menu-dark .navigation li.nav-item ul.menu-content li a:hover > * {
  transition : transform 0.25s ease;
  transform : translateX(5px);
}

.main-menu.menu-dark .navigation > li > a {
  margin : 0 15px;
}

.main-menu.menu-dark .navigation > li > a .label {
  margin-top : 4px;
  margin-right : 5px;
}

.main-menu.menu-dark .navigation > li > a i, .main-menu.menu-dark .navigation > li > a svg {
  position : relative;
  top : 1px;
}

.main-menu.menu-dark .navigation > li > ul li:first-of-type {
  margin-top : 7px !important;
}

.main-menu.menu-dark .navigation > li > ul li:not(.has-sub) {
  margin : 0 15px;
}

.main-menu.menu-dark .navigation > li > ul li.has-sub > a {
  margin : 0 15px;
}

.main-menu.menu-dark .navigation > li > ul li.has-sub > ul > li {
  margin : 0 15px;
}

.main-menu.menu-dark .navigation > li.open:not(.menu-item-closing) > a, .main-menu.menu-dark .navigation > li.sidebar-group-active > a {
  color : #C3C3C3;
  background : #0C102A;
  border-radius : 6px;
}

.main-menu.menu-dark .navigation > li:not(.open) > ul {
  display : none;
}

.main-menu.menu-dark .navigation > li:not(.open) > ul .active {
  background : transparent;
  box-shadow : none;
}

.main-menu.menu-dark .navigation > li.active {
  z-index : 1;
}

.main-menu.menu-dark .navigation > li.active > a {
  background : linear-gradient(118deg, #7367F0, rgba(115, 103, 240, 0.7));
  box-shadow : 0 0 10px 1px rgba(115, 103, 240, 0.7);
  color : #FFFFFF;
  font-weight : 400;
  border-radius : 4px;
}

.main-menu.menu-dark .navigation > li .active {
  background : #090D22;
}

.main-menu.menu-dark .navigation > li .active > a {
  color : #7367F0;
  margin-bottom : 0;
}

.main-menu.menu-dark .navigation > li .active .hover > a, .main-menu.menu-dark .navigation > li .active :hover > a {
  background : #0C102A;
}

.main-menu.menu-dark .navigation > li ul {
  padding : 0;
  margin : 0;
}

.main-menu.menu-dark .navigation > li ul li {
  color : #DCDCDC;
  background : transparent;
}

.main-menu.menu-dark .navigation > li ul li > a {
  padding : 10px 15px 10px 20px;
}

.main-menu.menu-dark .navigation > li ul li ul a {
  padding : 10px 15px 10px 53px;
}

.main-menu.menu-dark .navigation > li ul .has-sub:not(.open) > ul {
  display : none;
}

.main-menu.menu-dark .navigation > li ul .has-sub:not(.open) > ul .active {
  background : transparent;
  box-shadow : none;
}

.main-menu.menu-dark .navigation > li ul .open > a, .main-menu.menu-dark .navigation > li ul .sidebar-group-active > a {
  color : #DCDCDC;
  background : #0C102A;
  border-radius : 6px;
}

.main-menu.menu-dark .navigation > li ul .open > ul, .main-menu.menu-dark .navigation > li ul .sidebar-group-active > ul {
  display : block;
}

.main-menu.menu-dark .navigation > li ul .open > ul .open > ul, .main-menu.menu-dark .navigation > li ul .sidebar-group-active > ul .open > ul {
  display : block;
}

.main-menu.menu-dark .navigation > li ul .hover > a, .main-menu.menu-dark .navigation > li ul:hover > a {
  color : #E6E6E6;
}

.main-menu.menu-dark .navigation > li ul .active {
  background : linear-gradient(118deg, #7367F0, rgba(115, 103, 240, 0.7));
  box-shadow : 0 0 10px 1px rgba(115, 103, 240, 0.7);
  border-radius : 4px;
  z-index : 1;
}

.main-menu.menu-dark .navigation > li ul .active > a {
  color : #FFFFFF;
}

.main-menu.menu-fixed {
  position : fixed;
}

.main-menu.menu-static {
  height : 100%;
  padding-bottom : calc(100% - 20rem);
}

.main-menu.menu-static .main-menu-content {
  height : unset !important;
}

.main-menu.menu-shadow {
  box-shadow : 0 0 15px 0 rgba(34, 41, 47, 0.05);
}

.main-menu.menu-border {
  border-right : 1px solid #EBE9F1;
}

.main-menu .shadow-bottom {
  display : none;
  position : absolute;
  z-index : 2;
  height : 50px;
  width : 100%;
  pointer-events : none;
  margin-top : -0.7rem;
  filter : blur(5px);
  background : linear-gradient(#FFFFFF 41%, rgba(255, 255, 255, 0.11) 95%, rgba(255, 255, 255, 0));
}

.bordered-layout .main-menu .shadow-bottom {
  background : linear-gradient(#F8F8F8 41%, rgba(255, 255, 255, 0.11) 95%, rgba(255, 255, 255, 0));
}

.main-menu.menu-native-scroll .main-menu-content {
  overflow-y : scroll !important;
}

.main-menu .navbar-header {
  height : 100%;
  width : 260px;
  height : 4.45rem;
  position : relative;
  padding : 0.35rem 1rem 0.3rem 1.64rem;
  transition : 300ms ease all, background 0s;
}

.main-menu .navbar-header .navbar-brand {
  display : flex;
  align-items : center;
  margin-top : 1.35rem;
  font-size : inherit;
}

.main-menu .navbar-header .navbar-brand .brand-logo img {
  max-width : 36px;
}

.main-menu .navbar-header .navbar-brand .brand-text {
  color : #7367F0;
  padding-left : 1rem;
  margin-bottom : 0;
  font-weight : 600;
  letter-spacing : 0.01rem;
  font-size : 1.45rem;
  -webkit-animation : 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1 fadein;
          animation : 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1 fadein;
}

.main-menu .navbar-header .modern-nav-toggle {
  -webkit-animation : 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1 fadein;
          animation : 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1 fadein;
}

.main-menu .main-menu-content {
  height : calc(100% - 4.45rem) !important;
  position : relative;
}

.main-menu .main-menu-content .ps__rail-y {
  z-index : 3;
}

.main-menu ul {
  list-style : none;
  margin : 0;
  padding : 0;
}

.main-menu ul.navigation-main {
  overflow-x : hidden;
  padding-bottom : 0.75rem;
}

.main-menu ul.navigation-main > li:first-child {
  margin-top : 0.5rem;
}

.main-menu a {
  outline : none;
}

.main-menu a:hover, .main-menu a:focus {
  text-decoration : none;
}

.navigation {
  font-size : 1.1rem;
  font-family : 'Montserrat', Helvetica, Arial, serif;
  font-weight : 400;
  overflow-y : hidden;
  padding-bottom : 20px;
}

.navigation .navigation-header {
  font-family : 'Montserrat', Helvetica, Arial, serif;
  font-weight : 500;
  line-height : 1.2;
  padding : 12px 22px;
  font-size : 0.9rem;
  text-transform : uppercase;
}

.navigation li {
  position : relative;
  white-space : nowrap;
}

.navigation li a {
  display : block;
  text-overflow : ellipsis;
  overflow : hidden;
}

.navigation li a i, .navigation li a svg {
  height : 11px;
  width : 11px;
  font-size : 11px;
  margin-right : 1.38rem;
}

.navigation li.menu-item-animating {
  transition : height 0.25s ease-in-out;
}

.navigation li.disabled a {
  cursor : not-allowed;
  opacity : 0.65;
  pointer-events : none;
}

@media (max-width: 767.98px) {
  .menu-hide .main-menu, .menu-open .main-menu {
    transition : transform 0.25s, top 0.35s, height 0.35s;
  }
  .main-menu {
    transform : translate3d(-240px, 0, 0);
    -webkit-backface-visibility : hidden;
            backface-visibility : hidden;
    perspective : 1000;
  }
  .menu-open .main-menu {
    transform : translate3d(0, 0, 0);
  }
}

@media (min-width: 768px) {
  .drag-target {
    z-index : 0;
  }
}

.avatar {
  white-space : nowrap;
  background-color : #C3C3C3;
  border-radius : 50%;
  position : relative;
  cursor : pointer;
  color : #FFFFFF;
  display : inline-flex;
  font-size : 1rem;
  text-align : center;
  vertical-align : middle;
  font-weight : 600;
}

.avatar .avatar-content {
  width : 32px;
  height : 32px;
  display : flex;
  justify-content : center;
  align-items : center;
  border-radius : 50%;
  font-size : 0.857rem;
}

.avatar .avatar-content .avatar-icon {
  height : 1rem;
  width : 1rem;
}

.avatar [class*='avatar-status-'] {
  border-radius : 50%;
  width : 11px;
  height : 11px;
  position : absolute;
  right : 0;
  bottom : 0;
  border : 1px solid #FFFFFF;
}

.avatar [class*='avatar-status-'].avatar-status-lg, .avatar [class*='avatar-status-'].avatar-status-xl {
  width : 17px;
  height : 17px;
  border-width : 2px;
}

.avatar .avatar-status-online {
  background-color : #28C76F;
}

.avatar .avatar-status-busy {
  background-color : #EA5455;
}

.avatar .avatar-status-away {
  background-color : #FF9F43;
}

.avatar .avatar-status-offline {
  background-color : #82868B;
}

.avatar.avatar-tag {
  display : flex;
  align-items : center;
  justify-content : center;
  height : 42px;
  width : 42px;
  border-radius : 0.357rem;
}

.avatar img {
  border-radius : 50%;
}

.avatar.avatar-xl {
  font-size : 1.5rem;
}

.avatar.avatar-xl img {
  width : 70px;
  height : 70px;
}

.avatar.avatar-xl .avatar-content {
  height : 70px;
  width : 70px;
  font-size : 2.57rem;
}

.avatar.avatar-xl .avatar-content .avatar-icon, .avatar.avatar-xl .avatar-content i, .avatar.avatar-xl .avatar-content svg {
  height : 3rem;
  width : 3rem;
  font-size : 3rem;
}

.avatar.avatar-xl [class*='avatar-status-'] {
  right : 3px;
  bottom : 0;
}

.avatar.avatar-lg {
  font-size : 1.2rem;
}

.avatar.avatar-lg img {
  width : 50px;
  height : 50px;
}

.avatar.avatar-lg .avatar-content {
  width : 50px;
  height : 50px;
  font-size : 1.714rem;
}

.avatar.avatar-lg .avatar-content .avatar-icon, .avatar.avatar-lg .avatar-content i, .avatar.avatar-lg .avatar-content svg {
  height : 2rem;
  width : 2rem;
  font-size : 2rem;
}

.avatar.avatar-lg [class*='avatar-status-'] {
  right : 3px;
  bottom : 1px;
}

.avatar.avatar-sm .avatar-content {
  width : 24px;
  height : 24px;
}

.avatar.avatar-sm .avatar-content .avatar-icon, .avatar.avatar-sm .avatar-content i, .avatar.avatar-sm .avatar-content svg {
  height : 1rem;
  width : 1rem;
  font-size : 1rem;
}

.avatar.avatar-sm img {
  width : 24px;
  height : 24px;
}

.avatar.avatar-sm [class*='avatar-status-'] {
  right : -3px;
  bottom : -2px;
}

.avatar-group {
  display : flex;
  justify-content : flex-start;
}

.avatar-group .avatar {
  transition : all 0.25s ease;
}

.avatar-group .avatar img, .avatar-group .avatar .avatar-content {
  box-shadow : 0 0 0 2px #FFFFFF, inset 0 0 0 1px rgba(34, 41, 47, 0.07);
}

.avatar-group .avatar .avatar-content {
  background-color : #B7B9BC !important;
}

.avatar-group .avatar:hover {
  transition : all 0.25s ease;
}

.avatar-group .avatar.pull-up:hover {
  transform : translateY(-4px) scale(1.07);
}

.avatar-group .avatar:not(:first-child) {
  margin-left : -0.55rem;
}

.avatar-group .avatar-sm:not(:first-child) {
  margin-left : -0.4rem;
}

.avatar-group .avatar-lg:not(:first-child) {
  margin-left : -1rem;
}

.avatar-group .avatar-xl:not(:first-child) {
  margin-left : -1.5rem;
}

.navbar-container a.nav-link-search {
  float : left;
}

.navbar-container .search-input {
  width : 0;
  float : left;
  display : none;
}

.navbar-container .search-input input {
  width : 0;
  border : none;
  background : none;
  transition : all 0.2s ease-out;
  line-height : 16px;
  padding : 1.6rem 3.6rem;
}

.navbar-container .search-input.open {
  position : absolute;
  top : 0;
  left : 0;
  z-index : 1000;
  width : 100%;
  display : block;
  background : #FFFFFF;
  border-radius : 0.5rem;
}

.navbar-container .search-input.open .search-input-close, .navbar-container .search-input.open .search-input-icon {
  display : block;
}

.navbar-container .search-input.open input {
  width : 100%;
  outline : none;
  background : none;
  height : auto;
  box-shadow : none;
  transition : all 0.3s ease-out;
}

.navbar-container .search-input .search-list {
  max-height : 450px;
  height : auto;
  position : absolute;
  top : 100%;
  left : 0;
  background : #FFFFFF;
  width : 100%;
  margin-top : 0.5rem;
  padding-left : 0;
  border-radius : 0.357rem;
  display : none;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 875px) and (orientation: landscape) {
  .navbar-container .search-input .search-list {
    max-height : 250px;
  }
}

.navbar-container .search-input .search-list.search-list-bookmark {
  height : auto !important;
}

.navbar-container .search-input .search-list.search-list-bookmark i, .navbar-container .search-input .search-list.search-list-bookmark svg {
  font-size : 1.2rem;
  height : 1.2rem;
  width : 1.2rem;
}

.navbar-container .search-input .search-list.show {
  display : block;
  min-height : 3rem;
}

.navbar-container .search-input .search-list li a {
  padding : 0.9rem 1rem;
  color : #6E6B7B;
}

.navbar-container .search-input .search-list li a i, .navbar-container .search-input .search-list li a svg[class*='feather feather-'] {
  height : 1.25rem;
  width : 1.25rem;
  font-size : 1.25rem;
}

.navbar-container .search-input .search-list li:first-child {
  border-top-left-radius : 0.357rem;
  border-top-right-radius : 0.357rem;
}

.navbar-container .search-input .search-list li:last-child {
  border-bottom-left-radius : 0.357rem;
  border-bottom-right-radius : 0.357rem;
}

.navbar-container .search-input .search-list li.auto-suggestion {
  display : flex;
  align-items : center;
  cursor : pointer;
}

.navbar-container .search-input .search-list li.auto-suggestion:hover, .navbar-container .search-input .search-list li.auto-suggestion.current_item {
  background-color : #F8F8F8;
}

.navbar-container .search-input .search-input-icon {
  z-index : 2;
  display : none;
  position : absolute;
  left : 1.5rem;
  top : 32%;
  cursor : pointer;
}

.navbar-container .search-input .search-input-icon i, .navbar-container .search-input .search-input-icon svg {
  height : 1.25rem;
  width : 1.25rem;
  font-size : 1.25rem;
}

.navbar-container .search-input .search-input-close {
  z-index : 1;
  display : none;
  position : absolute;
  right : 2rem;
  top : 32%;
  cursor : pointer;
}

.navbar-container .search-input .search-input-close i, .navbar-container .search-input .search-input-close svg {
  height : 1.25rem;
  width : 1.25rem;
  font-size : 1.25rem;
}

.navbar-container .bookmark-input {
  position : absolute;
  top : 102%;
  width : 25%;
  display : none;
  background : #FFFFFF;
  border-radius : 0.357rem;
  box-shadow : 0 15px 30px 0 rgba(34, 41, 47, 0.11), 0 5px 15px 0 rgba(34, 41, 47, 0.08);
}

.navbar-container .bookmark-input.show {
  display : block;
}

.navbar-container .bookmark-input .bookmark-input-icon {
  position : absolute;
  z-index : 1;
  top : 20px;
  left : 24px;
}

.navbar-container .bookmark-input input {
  padding : 0.7rem 0.7rem 0.7rem 2.5rem;
  margin : 12px;
  width : calc(100% - 24px);
  background : #FFFFFF;
  border : 1px solid rgba(34, 41, 47, 0.2);
}

.navbar-container .bookmark-input input:focus {
  border : 1px solid #7367F0;
}

.navbar-container .bookmark-input .search-list {
  position : relative;
}

.navbar-container .bookmark-icon {
  cursor : pointer;
}

.navbar-container .dropdown.bookmark-dropdown {
  padding : 1.4rem 0.5rem 1.35rem;
}

.navbar-container .dropdown.bookmark-dropdown .dropdown-toggle:after {
  display : none;
}

.navbar-container .search-data-title {
  line-height : 1.25rem;
}

.navbar-dark .search-input .input {
  color : #F5F7FA;
  border-radius : 0.5rem;
}

.navbar-dark .search-input.open .input {
  color : #F5F7FA;
  border : 1px solid #F5F7FA;
}

.navbar-static .navbar-container .search-input.open {
  border-radius : 0;
}

.btn-social, .btn-social-icon {
  position : relative;
  padding-left : 4.95rem;
  text-align : left;
  white-space : nowrap;
  overflow : hidden;
  text-overflow : ellipsis;
}

.btn-social > :first-child, .btn-social-icon > :first-child {
  position : absolute;
  left : 0;
  top : 2px;
  bottom : 0;
  width : 2.95rem;
  line-height : 2.7rem;
  font-size : 1rem;
  text-align : center;
  border-right : 1px solid rgba(34, 41, 47, 0.2);
}

.btn-social-icon {
  height : 2.95rem;
  width : 2.95rem;
  padding : 0;
}

.btn-social-icon > :first-child {
  border : none;
  text-align : center;
  width : 100% !important;
}

.btn-adn {
  background-color : #D87A68;
  color : #FFFFFF;
  background-color : #D87A68;
  border-color : #FFFFFF;
  color : #FFFFFF !important;
  border : none;
}

.btn-adn:hover {
  color : #FFFFFF;
  background-color : #D87A68;
  border-color : #D87A68;
}

.btn-adn:focus, .btn-adn.focus {
  color : #FFFFFF;
  background-color : #D87A68;
  border-color : #D87A68;
  box-shadow : 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-adn.disabled, .btn-adn:disabled {
  color : #FFFFFF;
  background-color : #D87A68;
  border-color : #FFFFFF;
}

.btn-adn:not(:disabled):not(.disabled):active, .btn-adn:not(:disabled):not(.disabled).active, .show > .btn-adn.dropdown-toggle {
  color : #FFFFFF;
  background-color : #CE563F;
  border-color : #DFDFDF;
}

.btn-adn:not(:disabled):not(.disabled):active:focus, .btn-adn:not(:disabled):not(.disabled).active:focus, .show > .btn-adn.dropdown-toggle:focus {
  box-shadow : 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-bitbucket {
  background-color : #205081;
  color : #FFFFFF;
  background-color : #205081;
  border-color : #FFFFFF;
  color : #FFFFFF !important;
  border : none;
}

.btn-bitbucket:hover {
  color : #FFFFFF;
  background-color : #205081;
  border-color : #205081;
}

.btn-bitbucket:focus, .btn-bitbucket.focus {
  color : #FFFFFF;
  background-color : #205081;
  border-color : #205081;
  box-shadow : 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-bitbucket.disabled, .btn-bitbucket:disabled {
  color : #FFFFFF;
  background-color : #205081;
  border-color : #FFFFFF;
}

.btn-bitbucket:not(:disabled):not(.disabled):active, .btn-bitbucket:not(:disabled):not(.disabled).active, .show > .btn-bitbucket.dropdown-toggle {
  color : #FFFFFF;
  background-color : #163758;
  border-color : #DFDFDF;
}

.btn-bitbucket:not(:disabled):not(.disabled):active:focus, .btn-bitbucket:not(:disabled):not(.disabled).active:focus, .show > .btn-bitbucket.dropdown-toggle:focus {
  box-shadow : 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-dropbox {
  background-color : #1087DD;
  color : #FFFFFF;
  background-color : #1087DD;
  border-color : #FFFFFF;
  color : #FFFFFF !important;
  border : none;
}

.btn-dropbox:hover {
  color : #FFFFFF;
  background-color : #1087DD;
  border-color : #1087DD;
}

.btn-dropbox:focus, .btn-dropbox.focus {
  color : #FFFFFF;
  background-color : #1087DD;
  border-color : #1087DD;
  box-shadow : 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-dropbox.disabled, .btn-dropbox:disabled {
  color : #FFFFFF;
  background-color : #1087DD;
  border-color : #FFFFFF;
}

.btn-dropbox:not(:disabled):not(.disabled):active, .btn-dropbox:not(:disabled):not(.disabled).active, .show > .btn-dropbox.dropdown-toggle {
  color : #FFFFFF;
  background-color : #0D6AAD;
  border-color : #DFDFDF;
}

.btn-dropbox:not(:disabled):not(.disabled):active:focus, .btn-dropbox:not(:disabled):not(.disabled).active:focus, .show > .btn-dropbox.dropdown-toggle:focus {
  box-shadow : 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-facebook {
  background-color : #3B5998;
  color : #FFFFFF;
  background-color : #3B5998;
  border-color : #FFFFFF;
  color : #FFFFFF !important;
  border : none;
}

.btn-facebook:hover {
  color : #FFFFFF;
  background-color : #3B5998;
  border-color : #3B5998;
}

.btn-facebook:focus, .btn-facebook.focus {
  color : #FFFFFF;
  background-color : #3B5998;
  border-color : #3B5998;
  box-shadow : 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-facebook.disabled, .btn-facebook:disabled {
  color : #FFFFFF;
  background-color : #3B5998;
  border-color : #FFFFFF;
}

.btn-facebook:not(:disabled):not(.disabled):active, .btn-facebook:not(:disabled):not(.disabled).active, .show > .btn-facebook.dropdown-toggle {
  color : #FFFFFF;
  background-color : #2D4373;
  border-color : #DFDFDF;
}

.btn-facebook:not(:disabled):not(.disabled):active:focus, .btn-facebook:not(:disabled):not(.disabled).active:focus, .show > .btn-facebook.dropdown-toggle:focus {
  box-shadow : 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-flickr {
  background-color : #FF0084;
  color : #FFFFFF;
  background-color : #FF0084;
  border-color : #FFFFFF;
  color : #FFFFFF !important;
  border : none;
}

.btn-flickr:hover {
  color : #FFFFFF;
  background-color : #FF0084;
  border-color : #FF0084;
}

.btn-flickr:focus, .btn-flickr.focus {
  color : #FFFFFF;
  background-color : #FF0084;
  border-color : #FF0084;
  box-shadow : 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-flickr.disabled, .btn-flickr:disabled {
  color : #FFFFFF;
  background-color : #FF0084;
  border-color : #FFFFFF;
}

.btn-flickr:not(:disabled):not(.disabled):active, .btn-flickr:not(:disabled):not(.disabled).active, .show > .btn-flickr.dropdown-toggle {
  color : #FFFFFF;
  background-color : #CC006A;
  border-color : #DFDFDF;
}

.btn-flickr:not(:disabled):not(.disabled):active:focus, .btn-flickr:not(:disabled):not(.disabled).active:focus, .show > .btn-flickr.dropdown-toggle:focus {
  box-shadow : 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-foursquare {
  background-color : #F94877;
  color : #FFFFFF;
  background-color : #F94877;
  border-color : #FFFFFF;
  color : #FFFFFF !important;
  border : none;
}

.btn-foursquare:hover {
  color : #FFFFFF;
  background-color : #F94877;
  border-color : #F94877;
}

.btn-foursquare:focus, .btn-foursquare.focus {
  color : #FFFFFF;
  background-color : #F94877;
  border-color : #F94877;
  box-shadow : 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-foursquare.disabled, .btn-foursquare:disabled {
  color : #FFFFFF;
  background-color : #F94877;
  border-color : #FFFFFF;
}

.btn-foursquare:not(:disabled):not(.disabled):active, .btn-foursquare:not(:disabled):not(.disabled).active, .show > .btn-foursquare.dropdown-toggle {
  color : #FFFFFF;
  background-color : #F71752;
  border-color : #DFDFDF;
}

.btn-foursquare:not(:disabled):not(.disabled):active:focus, .btn-foursquare:not(:disabled):not(.disabled).active:focus, .show > .btn-foursquare.dropdown-toggle:focus {
  box-shadow : 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-github {
  background-color : #444444;
  color : #FFFFFF;
  background-color : #444444;
  border-color : #FFFFFF;
  color : #FFFFFF !important;
  border : none;
}

.btn-github:hover {
  color : #FFFFFF;
  background-color : #444444;
  border-color : #444444;
}

.btn-github:focus, .btn-github.focus {
  color : #FFFFFF;
  background-color : #444444;
  border-color : #444444;
  box-shadow : 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-github.disabled, .btn-github:disabled {
  color : #FFFFFF;
  background-color : #444444;
  border-color : #FFFFFF;
}

.btn-github:not(:disabled):not(.disabled):active, .btn-github:not(:disabled):not(.disabled).active, .show > .btn-github.dropdown-toggle {
  color : #FFFFFF;
  background-color : #2B2B2B;
  border-color : #DFDFDF;
}

.btn-github:not(:disabled):not(.disabled):active:focus, .btn-github:not(:disabled):not(.disabled).active:focus, .show > .btn-github.dropdown-toggle:focus {
  box-shadow : 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-google {
  background-color : #DD4B39;
  color : #FFFFFF;
  background-color : #DD4B39;
  border-color : #FFFFFF;
  color : #FFFFFF !important;
  border : none;
}

.btn-google:hover {
  color : #FFFFFF;
  background-color : #DD4B39;
  border-color : #DD4B39;
}

.btn-google:focus, .btn-google.focus {
  color : #FFFFFF;
  background-color : #DD4B39;
  border-color : #DD4B39;
  box-shadow : 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-google.disabled, .btn-google:disabled {
  color : #FFFFFF;
  background-color : #DD4B39;
  border-color : #FFFFFF;
}

.btn-google:not(:disabled):not(.disabled):active, .btn-google:not(:disabled):not(.disabled).active, .show > .btn-google.dropdown-toggle {
  color : #FFFFFF;
  background-color : #C23321;
  border-color : #DFDFDF;
}

.btn-google:not(:disabled):not(.disabled):active:focus, .btn-google:not(:disabled):not(.disabled).active:focus, .show > .btn-google.dropdown-toggle:focus {
  box-shadow : 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-instagram {
  background-color : #3F729B;
  color : #FFFFFF;
  background-color : #3F729B;
  border-color : #FFFFFF;
  color : #FFFFFF !important;
  border : none;
}

.btn-instagram:hover {
  color : #FFFFFF;
  background-color : #3F729B;
  border-color : #3F729B;
}

.btn-instagram:focus, .btn-instagram.focus {
  color : #FFFFFF;
  background-color : #3F729B;
  border-color : #3F729B;
  box-shadow : 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-instagram.disabled, .btn-instagram:disabled {
  color : #FFFFFF;
  background-color : #3F729B;
  border-color : #FFFFFF;
}

.btn-instagram:not(:disabled):not(.disabled):active, .btn-instagram:not(:disabled):not(.disabled).active, .show > .btn-instagram.dropdown-toggle {
  color : #FFFFFF;
  background-color : #305777;
  border-color : #DFDFDF;
}

.btn-instagram:not(:disabled):not(.disabled):active:focus, .btn-instagram:not(:disabled):not(.disabled).active:focus, .show > .btn-instagram.dropdown-toggle:focus {
  box-shadow : 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-linkedin {
  background-color : #007BB6;
  color : #FFFFFF;
  background-color : #007BB6;
  border-color : #FFFFFF;
  color : #FFFFFF !important;
  border : none;
}

.btn-linkedin:hover {
  color : #FFFFFF;
  background-color : #007BB6;
  border-color : #007BB6;
}

.btn-linkedin:focus, .btn-linkedin.focus {
  color : #FFFFFF;
  background-color : #007BB6;
  border-color : #007BB6;
  box-shadow : 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-linkedin.disabled, .btn-linkedin:disabled {
  color : #FFFFFF;
  background-color : #007BB6;
  border-color : #FFFFFF;
}

.btn-linkedin:not(:disabled):not(.disabled):active, .btn-linkedin:not(:disabled):not(.disabled).active, .show > .btn-linkedin.dropdown-toggle {
  color : #FFFFFF;
  background-color : #005983;
  border-color : #DFDFDF;
}

.btn-linkedin:not(:disabled):not(.disabled):active:focus, .btn-linkedin:not(:disabled):not(.disabled).active:focus, .show > .btn-linkedin.dropdown-toggle:focus {
  box-shadow : 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-microsoft {
  background-color : #2672EC;
  color : #FFFFFF;
  background-color : #2672EC;
  border-color : #FFFFFF;
  color : #FFFFFF !important;
  border : none;
}

.btn-microsoft:hover {
  color : #FFFFFF;
  background-color : #2672EC;
  border-color : #2672EC;
}

.btn-microsoft:focus, .btn-microsoft.focus {
  color : #FFFFFF;
  background-color : #2672EC;
  border-color : #2672EC;
  box-shadow : 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-microsoft.disabled, .btn-microsoft:disabled {
  color : #FFFFFF;
  background-color : #2672EC;
  border-color : #FFFFFF;
}

.btn-microsoft:not(:disabled):not(.disabled):active, .btn-microsoft:not(:disabled):not(.disabled).active, .show > .btn-microsoft.dropdown-toggle {
  color : #FFFFFF;
  background-color : #125ACD;
  border-color : #DFDFDF;
}

.btn-microsoft:not(:disabled):not(.disabled):active:focus, .btn-microsoft:not(:disabled):not(.disabled).active:focus, .show > .btn-microsoft.dropdown-toggle:focus {
  box-shadow : 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-odnoklassniki {
  background-color : #F4731C;
  color : #FFFFFF;
  background-color : #F4731C;
  border-color : #FFFFFF;
  color : #FFFFFF !important;
  border : none;
}

.btn-odnoklassniki:hover {
  color : #FFFFFF;
  background-color : #F4731C;
  border-color : #F4731C;
}

.btn-odnoklassniki:focus, .btn-odnoklassniki.focus {
  color : #FFFFFF;
  background-color : #F4731C;
  border-color : #F4731C;
  box-shadow : 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-odnoklassniki.disabled, .btn-odnoklassniki:disabled {
  color : #FFFFFF;
  background-color : #F4731C;
  border-color : #FFFFFF;
}

.btn-odnoklassniki:not(:disabled):not(.disabled):active, .btn-odnoklassniki:not(:disabled):not(.disabled).active, .show > .btn-odnoklassniki.dropdown-toggle {
  color : #FFFFFF;
  background-color : #D35B0A;
  border-color : #DFDFDF;
}

.btn-odnoklassniki:not(:disabled):not(.disabled):active:focus, .btn-odnoklassniki:not(:disabled):not(.disabled).active:focus, .show > .btn-odnoklassniki.dropdown-toggle:focus {
  box-shadow : 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-openid {
  background-color : #F7931E;
  color : #2A2E30;
  background-color : #F7931E;
  border-color : #FFFFFF;
  color : #FFFFFF !important;
  border : none;
}

.btn-openid:hover {
  color : #2A2E30;
  background-color : #F7931E;
  border-color : #F7931E;
}

.btn-openid:focus, .btn-openid.focus {
  color : #2A2E30;
  background-color : #F7931E;
  border-color : #F7931E;
  box-shadow : 0 0 0 0.2rem rgba(223, 224, 224, 0.5);
}

.btn-openid.disabled, .btn-openid:disabled {
  color : #2A2E30;
  background-color : #F7931E;
  border-color : #FFFFFF;
}

.btn-openid:not(:disabled):not(.disabled):active, .btn-openid:not(:disabled):not(.disabled).active, .show > .btn-openid.dropdown-toggle {
  color : #FFFFFF;
  background-color : #DA7908;
  border-color : #DFDFDF;
}

.btn-openid:not(:disabled):not(.disabled):active:focus, .btn-openid:not(:disabled):not(.disabled).active:focus, .show > .btn-openid.dropdown-toggle:focus {
  box-shadow : 0 0 0 0.2rem rgba(223, 224, 224, 0.5);
}

.btn-pinterest {
  background-color : #CB2027;
  color : #FFFFFF;
  background-color : #CB2027;
  border-color : #FFFFFF;
  color : #FFFFFF !important;
  border : none;
}

.btn-pinterest:hover {
  color : #FFFFFF;
  background-color : #CB2027;
  border-color : #CB2027;
}

.btn-pinterest:focus, .btn-pinterest.focus {
  color : #FFFFFF;
  background-color : #CB2027;
  border-color : #CB2027;
  box-shadow : 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-pinterest.disabled, .btn-pinterest:disabled {
  color : #FFFFFF;
  background-color : #CB2027;
  border-color : #FFFFFF;
}

.btn-pinterest:not(:disabled):not(.disabled):active, .btn-pinterest:not(:disabled):not(.disabled).active, .show > .btn-pinterest.dropdown-toggle {
  color : #FFFFFF;
  background-color : #9F191F;
  border-color : #DFDFDF;
}

.btn-pinterest:not(:disabled):not(.disabled):active:focus, .btn-pinterest:not(:disabled):not(.disabled).active:focus, .show > .btn-pinterest.dropdown-toggle:focus {
  box-shadow : 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-reddit {
  background-color : #EFF7FF;
  color : #2A2E30;
  background-color : #EFF7FF;
  border-color : #000000;
  color : #FFFFFF !important;
  border : none;
}

.btn-reddit:hover {
  color : #FFFFFF;
  background-color : #000000;
  border-color : #000000;
}

.btn-reddit:focus, .btn-reddit.focus {
  color : #FFFFFF;
  background-color : #000000;
  border-color : #000000;
  box-shadow : 0 0 0 0.2rem rgba(6, 7, 7, 0.5);
}

.btn-reddit.disabled, .btn-reddit:disabled {
  color : #2A2E30;
  background-color : #EFF7FF;
  border-color : #000000;
}

.btn-reddit:not(:disabled):not(.disabled):active, .btn-reddit:not(:disabled):not(.disabled).active, .show > .btn-reddit.dropdown-toggle {
  color : #2A2E30;
  background-color : #BCDEFF;
  border-color : black;
}

.btn-reddit:not(:disabled):not(.disabled):active:focus, .btn-reddit:not(:disabled):not(.disabled).active:focus, .show > .btn-reddit.dropdown-toggle:focus {
  box-shadow : 0 0 0 0.2rem rgba(6, 7, 7, 0.5);
}

.btn-soundcloud {
  background-color : #FF5500;
  color : #FFFFFF;
  background-color : #FF5500;
  border-color : #FFFFFF;
  color : #FFFFFF !important;
  border : none;
}

.btn-soundcloud:hover {
  color : #FFFFFF;
  background-color : #FF5500;
  border-color : #FF5500;
}

.btn-soundcloud:focus, .btn-soundcloud.focus {
  color : #FFFFFF;
  background-color : #FF5500;
  border-color : #FF5500;
  box-shadow : 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-soundcloud.disabled, .btn-soundcloud:disabled {
  color : #FFFFFF;
  background-color : #FF5500;
  border-color : #FFFFFF;
}

.btn-soundcloud:not(:disabled):not(.disabled):active, .btn-soundcloud:not(:disabled):not(.disabled).active, .show > .btn-soundcloud.dropdown-toggle {
  color : #FFFFFF;
  background-color : #CC4400;
  border-color : #DFDFDF;
}

.btn-soundcloud:not(:disabled):not(.disabled):active:focus, .btn-soundcloud:not(:disabled):not(.disabled).active:focus, .show > .btn-soundcloud.dropdown-toggle:focus {
  box-shadow : 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-tumblr {
  background-color : #2C4762;
  color : #FFFFFF;
  background-color : #2C4762;
  border-color : #FFFFFF;
  color : #FFFFFF !important;
  border : none;
}

.btn-tumblr:hover {
  color : #FFFFFF;
  background-color : #2C4762;
  border-color : #2C4762;
}

.btn-tumblr:focus, .btn-tumblr.focus {
  color : #FFFFFF;
  background-color : #2C4762;
  border-color : #2C4762;
  box-shadow : 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-tumblr.disabled, .btn-tumblr:disabled {
  color : #FFFFFF;
  background-color : #2C4762;
  border-color : #FFFFFF;
}

.btn-tumblr:not(:disabled):not(.disabled):active, .btn-tumblr:not(:disabled):not(.disabled).active, .show > .btn-tumblr.dropdown-toggle {
  color : #FFFFFF;
  background-color : #1C2E3F;
  border-color : #DFDFDF;
}

.btn-tumblr:not(:disabled):not(.disabled):active:focus, .btn-tumblr:not(:disabled):not(.disabled).active:focus, .show > .btn-tumblr.dropdown-toggle:focus {
  box-shadow : 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-twitter {
  background-color : #55ACEE;
  color : #2A2E30;
  background-color : #55ACEE;
  border-color : #FFFFFF;
  color : #FFFFFF !important;
  border : none;
}

.btn-twitter:hover {
  color : #2A2E30;
  background-color : #55ACEE;
  border-color : #55ACEE;
}

.btn-twitter:focus, .btn-twitter.focus {
  color : #2A2E30;
  background-color : #55ACEE;
  border-color : #55ACEE;
  box-shadow : 0 0 0 0.2rem rgba(223, 224, 224, 0.5);
}

.btn-twitter.disabled, .btn-twitter:disabled {
  color : #2A2E30;
  background-color : #55ACEE;
  border-color : #FFFFFF;
}

.btn-twitter:not(:disabled):not(.disabled):active, .btn-twitter:not(:disabled):not(.disabled).active, .show > .btn-twitter.dropdown-toggle {
  color : #FFFFFF;
  background-color : #2795E9;
  border-color : #DFDFDF;
}

.btn-twitter:not(:disabled):not(.disabled):active:focus, .btn-twitter:not(:disabled):not(.disabled).active:focus, .show > .btn-twitter.dropdown-toggle:focus {
  box-shadow : 0 0 0 0.2rem rgba(223, 224, 224, 0.5);
}

.btn-vimeo {
  background-color : #1AB7EA;
  color : #FFFFFF;
  background-color : #1AB7EA;
  border-color : #FFFFFF;
  color : #FFFFFF !important;
  border : none;
}

.btn-vimeo:hover {
  color : #FFFFFF;
  background-color : #1AB7EA;
  border-color : #1AB7EA;
}

.btn-vimeo:focus, .btn-vimeo.focus {
  color : #FFFFFF;
  background-color : #1AB7EA;
  border-color : #1AB7EA;
  box-shadow : 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-vimeo.disabled, .btn-vimeo:disabled {
  color : #FFFFFF;
  background-color : #1AB7EA;
  border-color : #FFFFFF;
}

.btn-vimeo:not(:disabled):not(.disabled):active, .btn-vimeo:not(:disabled):not(.disabled).active, .show > .btn-vimeo.dropdown-toggle {
  color : #FFFFFF;
  background-color : #1295BF;
  border-color : #DFDFDF;
}

.btn-vimeo:not(:disabled):not(.disabled):active:focus, .btn-vimeo:not(:disabled):not(.disabled).active:focus, .show > .btn-vimeo.dropdown-toggle:focus {
  box-shadow : 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-vk {
  background-color : #587EA3;
  color : #FFFFFF;
  background-color : #587EA3;
  border-color : #FFFFFF;
  color : #FFFFFF !important;
  border : none;
}

.btn-vk:hover {
  color : #FFFFFF;
  background-color : #587EA3;
  border-color : #587EA3;
}

.btn-vk:focus, .btn-vk.focus {
  color : #FFFFFF;
  background-color : #587EA3;
  border-color : #587EA3;
  box-shadow : 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-vk.disabled, .btn-vk:disabled {
  color : #FFFFFF;
  background-color : #587EA3;
  border-color : #FFFFFF;
}

.btn-vk:not(:disabled):not(.disabled):active, .btn-vk:not(:disabled):not(.disabled).active, .show > .btn-vk.dropdown-toggle {
  color : #FFFFFF;
  background-color : #466482;
  border-color : #DFDFDF;
}

.btn-vk:not(:disabled):not(.disabled):active:focus, .btn-vk:not(:disabled):not(.disabled).active:focus, .show > .btn-vk.dropdown-toggle:focus {
  box-shadow : 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-yahoo {
  background-color : #720E9E;
  color : #FFFFFF;
  background-color : #720E9E;
  border-color : #FFFFFF;
  color : #FFFFFF !important;
  border : none;
}

.btn-yahoo:hover {
  color : #FFFFFF;
  background-color : #720E9E;
  border-color : #720E9E;
}

.btn-yahoo:focus, .btn-yahoo.focus {
  color : #FFFFFF;
  background-color : #720E9E;
  border-color : #720E9E;
  box-shadow : 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-yahoo.disabled, .btn-yahoo:disabled {
  color : #FFFFFF;
  background-color : #720E9E;
  border-color : #FFFFFF;
}

.btn-yahoo:not(:disabled):not(.disabled):active, .btn-yahoo:not(:disabled):not(.disabled).active, .show > .btn-yahoo.dropdown-toggle {
  color : #FFFFFF;
  background-color : #500A6F;
  border-color : #DFDFDF;
}

.btn-yahoo:not(:disabled):not(.disabled):active:focus, .btn-yahoo:not(:disabled):not(.disabled).active:focus, .show > .btn-yahoo.dropdown-toggle:focus {
  box-shadow : 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-outline-adn {
  border : 1px solid #D87A68 !important;
  color : #D87A68;
}

.btn-outline-bitbucket {
  border : 1px solid #205081 !important;
  color : #205081;
}

.btn-outline-dropbox {
  border : 1px solid #1087DD !important;
  color : #1087DD;
}

.btn-outline-facebook {
  border : 1px solid #3B5998 !important;
  color : #3B5998;
}

.btn-outline-flickr {
  border : 1px solid #FF0084 !important;
  color : #FF0084;
}

.btn-outline-foursquare {
  border : 1px solid #F94877 !important;
  color : #F94877;
}

.btn-outline-github {
  border : 1px solid #444444 !important;
  color : #444444;
}

.btn-outline-google {
  border : 1px solid #DD4B39 !important;
  color : #DD4B39;
}

.btn-outline-instagram {
  border : 1px solid #3F729B !important;
  color : #3F729B;
}

.btn-outline-linkedin {
  border : 1px solid #007BB6 !important;
  color : #007BB6;
}

.btn-outline-microsoft {
  border : 1px solid #2672EC !important;
  color : #2672EC;
}

.btn-outline-odnoklassniki {
  border : 1px solid #F4731C !important;
  color : #F4731C;
}

.btn-outline-openid {
  border : 1px solid #F7931E !important;
  color : #F7931E;
}

.btn-outline-pinterest {
  border : 1px solid #CB2027 !important;
  color : #CB2027;
}

.btn-outline-reddit {
  border : 1px solid #FF4500 !important;
  color : #FF4500;
}

.btn-outline-soundcloud {
  border : 1px solid #FF5500 !important;
  color : #FF5500;
}

.btn-outline-tumblr {
  border : 1px solid #2C4762 !important;
  color : #2C4762;
}

.btn-outline-twitter {
  border : 1px solid #55ACEE !important;
  color : #55ACEE;
}

.btn-outline-vimeo {
  border : 1px solid #1AB7EA !important;
  color : #1AB7EA;
}

.btn-outline-vk {
  border : 1px solid #587EA3 !important;
  color : #587EA3;
}

.btn-outline-yahoo {
  border : 1px solid #720E9E !important;
  color : #720E9E;
}

.btn-outline-adn:hover {
  color : #AD412D;
  border : 1px solid #AD412D !important;
}

.btn-outline-bitbucket:hover {
  color : #0C1D2F;
  border : 1px solid #0C1D2F !important;
}

.btn-outline-dropbox:hover {
  color : #094D7E;
  border : 1px solid #094D7E !important;
}

.btn-outline-facebook:hover {
  color : #1E2E4F;
  border : 1px solid #1E2E4F !important;
}

.btn-outline-flickr:hover {
  color : #99004F;
  border : 1px solid #99004F !important;
}

.btn-outline-foursquare:hover {
  color : #D4073D;
  border : 1px solid #D4073D !important;
}

.btn-outline-github:hover {
  color : #111111;
  border : 1px solid #111111 !important;
}

.btn-outline-google:hover {
  color : #96271A;
  border : 1px solid #96271A !important;
}

.btn-outline-instagram:hover {
  color : #223D52;
  border : 1px solid #223D52 !important;
}

.btn-outline-linkedin:hover {
  color : #003650;
  border : 1px solid #003650 !important;
}

.btn-outline-microsoft:hover {
  color : #0E459E;
  border : 1px solid #0E459E !important;
}

.btn-outline-odnoklassniki:hover {
  color : #A24608;
  border : 1px solid #A24608 !important;
}

.btn-outline-openid:hover {
  color : #A95E06;
  border : 1px solid #A95E06 !important;
}

.btn-outline-pinterest:hover {
  color : #731216;
  border : 1px solid #731216 !important;
}

.btn-outline-reddit:hover {
  color : #992900;
  border : 1px solid #992900 !important;
}

.btn-outline-soundcloud:hover {
  color : #993300;
  border : 1px solid #993300 !important;
}

.btn-outline-tumblr:hover {
  color : #0C141C;
  border : 1px solid #0C141C !important;
}

.btn-outline-twitter:hover {
  color : #147BC9;
  border : 1px solid #147BC9 !important;
}

.btn-outline-vimeo:hover {
  color : #0D7091;
  border : 1px solid #0D7091 !important;
}

.btn-outline-vk:hover {
  color : #344B61;
  border : 1px solid #344B61 !important;
}

.btn-outline-yahoo:hover {
  color : #2E0640;
  border : 1px solid #2E0640 !important;
}

.bg-adn {
  background-color : #D87A68;
}

.bg-bitbucket {
  background-color : #205081;
}

.bg-dropbox {
  background-color : #1087DD;
}

.bg-facebook {
  background-color : #3B5998;
}

.bg-flickr {
  background-color : #FF0084;
}

.bg-foursquare {
  background-color : #F94877;
}

.bg-github {
  background-color : #444444;
}

.bg-google {
  background-color : #DD4B39;
}

.bg-instagram {
  background-color : #3F729B;
}

.bg-linkedin {
  background-color : #007BB6;
}

.bg-microsoft {
  background-color : #2672EC;
}

.bg-odnoklassniki {
  background-color : #F4731C;
}

.bg-openid {
  background-color : #F7931E;
}

.bg-pinterest {
  background-color : #CB2027;
}

.bg-reddit {
  background-color : #FF4500;
}

.bg-soundcloud {
  background-color : #FF5500;
}

.bg-tumblr {
  background-color : #2C4762;
}

.bg-twitter {
  background-color : #55ACEE;
}

.bg-vimeo {
  background-color : #1AB7EA;
}

.bg-vk {
  background-color : #587EA3;
}

.bg-yahoo {
  background-color : #720E9E;
}

.demo-inline-spacing {
  display : flex;
  flex-wrap : wrap;
  justify-content : flex-start;
  align-items : center;
}

.demo-inline-spacing > * {
  margin-right : 1.5rem;
  margin-top : 1.5rem;
}

.demo-vertical-spacing > * {
  margin : 0;
  margin-bottom : 1.5rem;
}

.demo-vertical-spacing > *:last-child {
  margin : 0;
}

.demo-spacing-0 > *:last-child {
  margin : 0;
}

.icon-wrapper .feather {
  height : 24px;
  width : 24px;
}

.scroll-example {
  padding : 0.5rem;
  position : relative;
  border : 2px solid #DFDFDF;
  overflow : auto;
}

.scroll-example .horz-scroll-content {
  width : 1200px;
}

.browser {
  background : #E0E0E0;
  border : 4px solid #E0E0E0;
  width : 100%;
  height : 12rem;
  padding-top : 20px;
  margin : 0 0 10px;
  box-sizing : border-box;
}

.browser iframe {
  border : 0;
  background : #FFFFFF;
  height : 100%;
  width : 100%;
}

.loader-wrapper {
  height : 8em;
}

.maintenance-icon {
  font-size : 4rem;
}

.animationIcon {
  right : 30px;
  bottom : 10px;
}

#component-breadcrumbs .breadcrumb:not(last-child) {
  margin-bottom : 0.5rem;
}

#breadcrumb-alignment .breadcrumb-wrapper {
  box-shadow : 1px 1px 10px rgba(34, 41, 47, 0.1);
  padding : 1px;
  border-radius : 0.357rem;
}

#breadcrumb-alignment .breadcrumb-wrapper .breadcrumb {
  margin-top : 0.8rem;
  margin-bottom : 0.8rem;
}

.dark-layout #breadcrumb-alignment .breadcrumb-wrapper {
  box-shadow : 1px 1px 10px rgba(0, 0, 0, 0.24);
}

.buy-now {
  position : fixed;
  bottom : 5%;
  right : 79px;
  z-index : 1031;
}

.buy-now .btn {
  box-shadow : 0 1px 20px 1px #EA5455 !important;
}

.buy-now .btn:hover {
  box-shadow : none !important;
}

.customizer {
  width : 400px;
  right : -400px;
  padding : 0;
  background-color : #FFFFFF;
  z-index : 1051;
  position : fixed;
  top : 0;
  bottom : 0;
  height : 100vh;
  height : calc(var(--vh, 1vh) * 100);
  transition : right 0.4s cubic-bezier(0.05, 0.74, 0.2, 0.99);
  -webkit-backface-visibility : hidden;
          backface-visibility : hidden;
  border-left : 1px solid rgba(34, 41, 47, 0.05);
}

.customizer.open {
  box-shadow : 0 15px 30px 0 rgba(34, 41, 47, 0.11), 0 5px 15px 0 rgba(34, 41, 47, 0.08);
  right : 0;
}

.customizer .customizer-content {
  position : relative;
  height : 100%;
}

.customizer .customizer-close {
  position : absolute;
  right : 0.6rem;
  top : 0.6rem;
  padding : 7px;
  width : auto;
  z-index : 10;
  color : #5E5873;
}

.customizer .customizer-close i, .customizer .customizer-close svg {
  font-size : 1.5rem;
  height : 1.5rem;
  width : 1.5rem;
}

.customizer .customizer-toggle {
  background : #7367F0;
  color : #FFFFFF !important;
  display : block;
  box-shadow : -3px 0 8px rgba(34, 41, 47, 0.1);
  border-top-left-radius : 6px;
  border-bottom-left-radius : 6px;
  position : absolute;
  top : 50%;
  width : 38px;
  height : 38px;
  left : -39px;
  text-align : center;
  line-height : 40px;
  cursor : pointer;
}

.customizer .color-box {
  height : 24px;
  width : 24px;
  margin : 0.5rem;
  border-radius : 0.5rem;
  cursor : pointer;
  position : relative;
}

.customizer .color-box.selected:after {
  content : '';
  border : 1px solid #7367F0;
  height : 30px;
  width : 30px;
  top : -3px;
  left : -3px;
  position : absolute;
  border-radius : 0.5rem;
}

.customizer .color-box.selected.border:after {
  top : -4px;
  left : -4px;
}

.divider {
  display : block;
  text-align : center;
  overflow : hidden;
  white-space : nowrap;
  margin : 1rem 0;
}

.divider .divider-text {
  position : relative;
  display : inline-block;
  font-size : 0.9375rem;
  padding : 0 1rem;
}

.divider .divider-text i, .divider .divider-text svg {
  height : 1rem;
  width : 1rem;
  font-size : 1rem;
}

.divider .divider-text:before, .divider .divider-text:after {
  content : '';
  position : absolute;
  top : 50%;
  width : 9999px;
  border-top : 1px solid #EBE9F1;
}

.divider .divider-text:before {
  right : 100%;
}

.divider .divider-text:after {
  left : 100%;
}

.divider.divider-left .divider-text {
  float : left;
  padding-left : 0;
}

.divider.divider-left .divider-text:before {
  display : none;
}

.divider.divider-left-center .divider-text {
  left : -25%;
}

.divider.divider-right .divider-text {
  float : right;
  padding-right : 0;
}

.divider.divider-right .divider-text:after {
  display : none;
}

.divider.divider-right-center .divider-text {
  right : -25%;
}

.divider.divider-dotted .divider-text:before, .divider.divider-dotted .divider-text:after {
  border-style : dotted;
  border-width : 1px;
  border-top-width : 0;
  border-color : #EBE9F1;
}

.divider.divider-dashed .divider-text:before, .divider.divider-dashed .divider-text:after {
  border-style : dashed;
  border-width : 1px;
  border-top-width : 0;
  border-color : #EBE9F1;
}

.timeline {
  padding : 0;
  margin-bottom : 0;
  margin-left : 1rem;
  list-style : none;
}

.timeline .timeline-item {
  position : relative;
  padding-left : 2.5rem;
  border-left : 1px solid #EBE9F1;
}

.timeline .timeline-item:not(:last-child) {
  padding-bottom : 1.8rem;
}

.timeline .timeline-item .timeline-point {
  position : absolute;
  left : -0.85rem;
  top : 0;
  z-index : 2;
  display : flex;
  justify-content : center;
  align-items : center;
  height : 1.75rem;
  width : 1.75rem;
  text-align : center;
  border-radius : 50%;
  border : 1px solid #7367F0;
  background-color : #FFFFFF;
}

.timeline .timeline-item .timeline-point.timeline-point-indicator {
  left : -0.412rem;
  top : 0.07rem;
  height : 12px;
  width : 12px;
  border : 0;
  background-color : #7367F0;
}

.timeline .timeline-item .timeline-point.timeline-point-indicator:before {
  content : '';
  background : rgba(115, 103, 240, 0.12);
  height : 20px;
  width : 20px;
  display : block;
  position : absolute;
  top : -0.285rem;
  left : -0.285rem;
  border-radius : 50%;
}

.timeline .timeline-item .timeline-point i, .timeline .timeline-item .timeline-point svg {
  color : #7367F0;
  font-size : 0.85rem;
  vertical-align : baseline;
}

.timeline .timeline-item .timeline-event {
  position : relative;
  width : 100%;
  min-height : 4rem;
}

.timeline .timeline-item .timeline-event .timeline-event-time {
  font-size : 0.85rem;
  color : #B9B9C3;
}

.timeline .timeline-item:last-of-type {
  border-left-color : transparent !important;
}

.timeline .timeline-item:last-of-type:after {
  content : '';
  position : absolute;
  left : -1px;
  bottom : 0;
  width : 1px;
  height : 100%;
  background : linear-gradient(#EBE9F1, transparent);
}

@media screen and (min-width: 0) {
  head {
    font-family : 'xs 0px';
  }
  body:after {
    content : 'xs - min-width: 0px';
  }
}

@media screen and (min-width: 544px) {
  head {
    font-family : 'sm 544px';
  }
  body:after {
    content : 'sm - min-width: 544px';
  }
}

@media screen and (min-width: 768px) {
  head {
    font-family : 'md 768px';
  }
  body:after {
    content : 'md - min-width: 768px';
  }
}

@media screen and (min-width: 992px) {
  head {
    font-family : 'lg 992px';
  }
  body:after {
    content : 'lg - min-width: 992px';
  }
}

@media screen and (min-width: 1200px) {
  head {
    font-family : 'xl 1200px';
  }
  body:after {
    content : 'xl - min-width: 1200px';
  }
}

head {
  clear : both;
}

head title {
  font-family : 'xs 0px, sm 544px, md 768px, lg 992px, xl 1200px';
}

body:after {
  display : none;
}

*[data-usn-if] {
  display : none;
}

.select2-container {
  width : 100% !important;
  margin : 0;
  display : inline-block;
  position : relative;
  vertical-align : middle;
  box-sizing : border-box;
}

.select2-container--classic:focus, .select2-container--default:focus {
  outline : none;
}

.select2-container--classic .select2-selection__choice, .select2-container--default .select2-selection__choice {
  font-size : 0.9rem;
  margin-top : 6px !important;
}

.select2-container--classic .select2-selection--single, .select2-container--default .select2-selection--single {
  min-height : 2.714rem;
  padding : 5px;
  border : 1px solid #D8D6DE;
}

.select2-container--classic .select2-selection--single:focus, .select2-container--default .select2-selection--single:focus {
  outline : 0;
  border-color : #7367F0 !important;
  box-shadow : 0 3px 10px 0 rgba(34, 41, 47, 0.1) !important;
}

.select2-container--classic .select2-selection--single .select2-selection__rendered i, .select2-container--classic .select2-selection--single .select2-selection__rendered svg, .select2-container--default .select2-selection--single .select2-selection__rendered i, .select2-container--default .select2-selection--single .select2-selection__rendered svg {
  font-size : 1.15rem;
  height : 1.15rem;
  width : 1.15rem;
  margin-right : 0.5rem;
}

.select2-container--classic .select2-selection--single .select2-selection__arrow b, .select2-container--default .select2-selection--single .select2-selection__arrow b {
  background-image : url('data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 24 24\' fill=\'none\' stroke=\'%23d8d6de\' stroke-width=\'2\' stroke-linecap=\'round\' stroke-linejoin=\'round\' class=\'feather feather-chevron-down\'%3E%3Cpolyline points=\'6 9 12 15 18 9\'%3E%3C/polyline%3E%3C/svg%3E');
  background-size : 18px 14px, 18px 14px;
  background-repeat : no-repeat;
  height : 1rem;
  padding-right : 1.5rem;
  margin-left : 0;
  margin-top : 0;
  left : -8px;
  border-style : none;
}

.select2-container--classic.select2-container--open, .select2-container--default.select2-container--open {
  box-shadow : 0 5px 25px rgba(34, 41, 47, 0.1);
}

.select2-container--classic.select2-container--open .select2-selection--single, .select2-container--default.select2-container--open .select2-selection--single {
  border-color : #7367F0 !important;
  outline : 0;
}

.select2-container--classic.select2-container--focus, .select2-container--default.select2-container--focus {
  outline : 0;
}

.select2-container--classic.select2-container--focus .select2-selection--multiple, .select2-container--default.select2-container--focus .select2-selection--multiple {
  border-color : #7367F0 !important;
  outline : 0;
}

.select2-container--classic .select2-dropdown, .select2-container--default .select2-dropdown {
  border-color : #D8D6DE;
}

.select2-container--classic .select2-search--dropdown, .select2-container--default .select2-search--dropdown {
  padding : 0.5rem;
}

.select2-container--classic .select2-search--dropdown .select2-search__field, .select2-container--default .select2-search--dropdown .select2-search__field {
  outline : none !important;
  border-radius : 0.357rem;
  border-color : #D8D6DE;
  padding : 0.438rem 1rem;
}

.select2-container--classic .select2-selection--multiple, .select2-container--default .select2-selection--multiple {
  min-height : 38px !important;
  border : 1px solid #D8D6DE;
}

.select2-container--classic .select2-selection--multiple:focus, .select2-container--default .select2-selection--multiple:focus {
  outline : 0;
  border-color : #7367F0 !important;
  box-shadow : 0 3px 10px 0 rgba(34, 41, 47, 0.1) !important;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice, .select2-container--default .select2-selection--multiple .select2-selection__choice {
  background-color : #7367F0 !important;
  border-color : #7367F0 !important;
  color : #FFFFFF;
  padding : 2px 5px;
}

.select2-container--classic .select2-selection--multiple .select2-selection__rendered, .select2-container--default .select2-selection--multiple .select2-selection__rendered {
  padding : 0 6px;
}

.select2-container--classic .select2-selection--multiple .select2-selection__rendered li .select2-search__field, .select2-container--default .select2-selection--multiple .select2-selection__rendered li .select2-search__field {
  margin-top : 7px;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove, .select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  float : right;
  margin-left : 0.7rem;
  margin-right : 0.5rem;
  font-size : 0;
  display : inline-block;
  position : relative;
  line-height : 1rem;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove:before, .select2-container--default .select2-selection--multiple .select2-selection__choice__remove:before {
  content : '';
  background-image : url('data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 24 24\' fill=\'none\' stroke=\'%23fff\' stroke-width=\'2\' stroke-linecap=\'round\' stroke-linejoin=\'round\' class=\'feather feather-x\'%3E%3Cline x1=\'18\' y1=\'6\' x2=\'6\' y2=\'18\'%3E%3C/line%3E%3Cline x1=\'6\' y1=\'6\' x2=\'18\' y2=\'18\'%3E%3C/line%3E%3C/svg%3E');
  background-size : 0.85rem;
  height : 0.85rem;
  width : 0.85rem;
  position : absolute;
  top : 22%;
  left : -4px;
}

.select2-container--classic .select2-selection--multiple i, .select2-container--classic .select2-selection--multiple svg, .select2-container--default .select2-selection--multiple i, .select2-container--default .select2-selection--multiple svg {
  position : relative;
  top : 1px;
  margin-right : 0.5rem;
  height : 1.15rem;
  width : 1.15rem;
  font-size : 1.15rem;
  padding-left : 1px;
}

.select2-container--classic .select2-results__group, .select2-container--default .select2-results__group {
  padding : 6px 9px;
  font-weight : 600;
}

.select2-container--classic .select2-results__option, .select2-container--default .select2-results__option {
  padding : 0.428rem 1rem;
}

.select2-container--classic .select2-results__option[role='group'], .select2-container--default .select2-results__option[role='group'] {
  padding : 0;
}

.select2-container--classic .select2-results__option[aria-selected='true'], .select2-container--default .select2-results__option[aria-selected='true'] {
  background-color : #7367F0 !important;
  color : white !important;
}

.select2-container--classic .select2-results__option i, .select2-container--classic .select2-results__option svg, .select2-container--default .select2-results__option i, .select2-container--default .select2-results__option svg {
  height : 1.15rem;
  width : 1.15rem;
  font-size : 1.15rem;
  margin-right : 0.5rem;
}

.select2-container--classic .select2-results__option--highlighted, .select2-container--default .select2-results__option--highlighted {
  background-color : rgba(115, 103, 240, 0.12) !important;
  color : #7367F0 !important;
}

.select2-container--classic .select2-result-repository__avatar img, .select2-container--default .select2-result-repository__avatar img {
  width : 50px;
}

.select2-container--classic .select-lg, .select2-container--default .select-lg {
  min-height : 2.714rem !important;
  font-size : 1.2rem;
  margin-bottom : 0 !important;
  padding : 0.3rem 0.7rem;
}

.select2-container--classic .select-lg.select2-selection--single .select2-selection__rendered, .select2-container--default .select-lg.select2-selection--single .select2-selection__rendered {
  padding-top : -0.1rem;
}

.select2-container--classic .select-lg.select2-selection--single .select2-selection__arrow, .select2-container--default .select-lg.select2-selection--single .select2-selection__arrow {
  top : 0rem !important;
}

.select2-container--classic .select-lg.select2-selection--multiple, .select2-container--default .select-lg.select2-selection--multiple {
  padding : 0 0.2rem;
}

.select2-container--classic .select-lg.select2-selection--multiple .select2-selection__rendered, .select2-container--default .select-lg.select2-selection--multiple .select2-selection__rendered {
  padding-top : 0 !important;
}

.select2-container--classic .select-lg.select2-selection--multiple .select2-selection__rendered li, .select2-container--default .select-lg.select2-selection--multiple .select2-selection__rendered li {
  font-size : 1.2rem;
}

.select2-container--classic .select-lg.select2-selection--multiple .select2-selection__rendered li .select2-search__field, .select2-container--default .select-lg.select2-selection--multiple .select2-selection__rendered li .select2-search__field {
  margin-top : 10px;
}

.select2-container--classic .select-lg.select2-selection--multiple .select2-selection__rendered .select2-selection__choice, .select2-container--default .select-lg.select2-selection--multiple .select2-selection__rendered .select2-selection__choice {
  padding : 5px;
}

.select2-container--classic .select-lg.select2-selection--multiple .select2-selection__rendered .select2-selection__choice .select2-selection__choice__remove:before, .select2-container--default .select-lg.select2-selection--multiple .select2-selection__rendered .select2-selection__choice .select2-selection__choice__remove:before {
  top : 46%;
}

.select2-container--classic .select-sm, .select2-container--default .select-sm {
  min-height : 2.142rem !important;
  padding : 0 0.2rem;
  font-size : 0.75rem;
  margin-bottom : 0 !important;
  line-height : 1.5;
}

.select2-container--classic .select-sm.select2-selection--single .select2-selection__arrow, .select2-container--default .select-sm.select2-selection--single .select2-selection__arrow {
  top : -0.3rem !important;
}

.select2-container--classic .select-sm.select2-selection--multiple, .select2-container--default .select-sm.select2-selection--multiple {
  line-height : 1.3;
}

.select2-container--classic .select-sm.select2-selection--multiple .select2-selection__rendered, .select2-container--default .select-sm.select2-selection--multiple .select2-selection__rendered {
  padding : 3px;
}

.select2-container--classic .select-sm.select2-selection--multiple .select2-selection__rendered li, .select2-container--default .select-sm.select2-selection--multiple .select2-selection__rendered li {
  font-size : 0.75rem;
  margin-top : 2px;
}

.select2-container--classic .select-sm.select2-selection--multiple .select2-selection__rendered li .select2-search__field, .select2-container--default .select-sm.select2-selection--multiple .select2-selection__rendered li .select2-search__field {
  margin-top : 4px;
}

.select2-container--classic .select-sm.select2-selection--multiple .select2-selection__choice, .select2-container--default .select-sm.select2-selection--multiple .select2-selection__choice {
  padding : 0 0.2rem;
}

.select2-container--classic .select-sm.select2-selection--multiple .select2-selection__choice .select2-selection__choice__remove:before, .select2-container--default .select-sm.select2-selection--multiple .select2-selection__choice .select2-selection__choice__remove:before {
  top : 5%;
}

.select2-container--classic .select-sm.select2-selection--multiple .select2-search--inline .select2-search__field, .select2-container--default .select-sm.select2-selection--multiple .select2-search--inline .select2-search__field {
  margin-top : 0;
}

.dark-layout .select2-container .select2-selection, .dark-layout .select2-container .select2-search__field, .dark-layout .select2-container .select2-selection__placeholder {
  background : #283046;
  border-color : #3B4253;
  color : #B4B7BD;
}

.dark-layout .select2-container .select2-selection__rendered {
  color : #B4B7BD;
}

.dark-layout .select2-container .select2-dropdown {
  background-color : #283046;
  border-color : #3B4253;
}

.dark-layout .select2-container .select2-selection--multiple .select2-selection__choice {
  background : rgba(115, 103, 240, 0.12) !important;
  color : #7367F0 !important;
  border : none;
}

.dark-layout .select2-container .select2-selection--multiple .select2-selection__choice .select2-selection__choice__remove {
  color : #7367F0 !important;
}

.dark-layout .select2-container .select2-selection--multiple .select2-selection__choice__remove:before {
  background-image : url('data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 24 24\' fill=\'none\' stroke=\'%237367f0\' stroke-width=\'2\' stroke-linecap=\'round\' stroke-linejoin=\'round\' class=\'feather feather-x\'%3E%3Cline x1=\'18\' y1=\'6\' x2=\'6\' y2=\'18\'%3E%3C/line%3E%3Cline x1=\'6\' y1=\'6\' x2=\'18\' y2=\'18\'%3E%3C/line%3E%3C/svg%3E');
}

.dark-layout .select2-container .select2-results__option[aria-disabled='true'] {
  color : #676D7D;
}

.dark-layout .select2-container .select2-selection__arrow b {
  background-image : url('data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 24 24\' fill=\'none\' stroke=\'%23b4b7bd\' stroke-width=\'2\' stroke-linecap=\'round\' stroke-linejoin=\'round\' class=\'feather feather-chevron-down\'%3E%3Cpolyline points=\'6 9 12 15 18 9\'%3E%3C/polyline%3E%3C/svg%3E');
}

.dark-layout .select2-container--disabled .select2-selection {
  opacity : 0.5;
}

table.dataTable {
  width : 100% !important;
  border-collapse : collapse !important;
}

table.dataTable td, table.dataTable th {
  padding : 0.72rem 1.5rem;
  vertical-align : middle;
}

table.dataTable thead .sorting, table.dataTable thead .sorting_asc, table.dataTable thead .sorting_desc {
  padding-right : inherit;
}

table.dataTable thead .sorting:before, table.dataTable thead .sorting_asc:before, table.dataTable thead .sorting_desc:before {
  background-image : url('data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 24 24\' fill=\'none\' stroke=\'%235e5873\' stroke-width=\'2\' stroke-linecap=\'round\' stroke-linejoin=\'round\' class=\'feather feather-chevron-up\'%3E%3Cpolyline points=\'18 15 12 9 6 15\'%3E%3C/polyline%3E%3C/svg%3E');
  background-repeat : no-repeat;
  background-position : center;
  background-size : 12px;
  color : #6E6B7B;
  width : 14px;
  height : 14px;
  content : '';
  right : 0.3rem;
  top : 0.5rem;
}

table.dataTable thead .sorting:after, table.dataTable thead .sorting_asc:after, table.dataTable thead .sorting_desc:after {
  background-image : url('data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 24 24\' fill=\'none\' stroke=\'%235e5873\' stroke-width=\'2\' stroke-linecap=\'round\' stroke-linejoin=\'round\' class=\'feather feather-chevron-down\'%3E%3Cpolyline points=\'6 9 12 15 18 9\'%3E%3C/polyline%3E%3C/svg%3E');
  background-repeat : no-repeat;
  background-position : center;
  background-size : 12px;
  color : #6E6B7B;
  width : 14px;
  height : 14px;
  content : '';
  right : 0.3rem;
  top : 1.3rem;
}

table.dataTable .dt-checkboxes-cell .custom-control {
  padding-left : 0;
  padding-right : 1.25rem;
}

div.dataTables_wrapper .dt-button-collection {
  background-color : #FFFFFF;
}

div.dataTables_wrapper .dt-button-collection > div[role='menu'] {
  text-align : left;
  box-shadow : 0 5px 16px -8px;
  min-width : 8rem;
}

div.dataTables_wrapper .dt-button-collection [class*='buttons-']:focus {
  outline : 0;
}

table.dataTable.dtr-column > tbody > tr > td.control:before, table.dataTable.dtr-column > tbody > tr > th.control:before {
  line-height : 16px;
}

div.dataTables_wrapper div.dataTables_filter label, div.dataTables_wrapper div.dataTables_length label {
  margin-top : 1rem;
  margin-bottom : 0.5rem;
}

div.dataTables_wrapper div.dataTables_filter select, div.dataTables_wrapper div.dataTables_length select {
  background-position : calc(100% - 3px) 11px, calc(100% - 20px) 13px, 100% 0;
  padding : 0 0.8rem;
  width : 5rem;
  margin : 0 0.5rem;
}

div.dataTables_wrapper div.dataTables_filter input {
  margin-left : 0.75rem;
}

div.dataTables_wrapper div.dataTables_paginate ul.pagination {
  margin-top : 1rem;
  margin-bottom : 1.5rem;
}

div.dataTables_wrapper .dataTables_info {
  padding-top : 1.25rem !important;
  color : #B9B9C3;
}

tr.group {
  background-color : #FAFAFC;
}

@media only screen and (max-width: 768px) {
  div.dataTables_wrapper div.dataTables_paginate ul.pagination {
    justify-content : center;
  }
  div.dataTables_wrapper div.dataTables_info {
    white-space : inherit;
  }
  div.dt-buttons {
    justify-content : center;
    margin-top : 1rem;
  }
  div.dt-buttons button {
    margin-bottom : 0.5rem;
  }
}

@media (max-width: 575.98px) {
  .dtr-bs-modal .modal-body {
    padding-left : 0;
    padding-right : 0;
    overflow-x : scroll;
  }
  .dtr-bs-modal .table.dtr-details {
    margin : 0 auto 1rem;
  }
  .dtr-bs-modal .table.dtr-details td {
    padding-left : 0;
    padding-right : 0;
  }
  .dtr-bs-modal .table.dtr-details tr td:first-child {
    padding-left : 0.75rem;
  }
  div.dataTables_wrapper {
    overflow : scroll;
  }
}

.bootstrap-touchspin.input-group {
  width : 8.4rem;
  align-items : center;
}

.bootstrap-touchspin.input-group .form-control {
  padding : 5px;
  height : auto;
  border : 0;
  background-color : #F8F8F8;
  border-radius : 0.357rem !important;
  text-align : center;
  font-weight : 500;
}

.bootstrap-touchspin.input-group .form-control:focus {
  z-index : 1;
  box-shadow : none;
}

.bootstrap-touchspin .bootstrap-touchspin-injected {
  margin : 0 !important;
}

.bootstrap-touchspin .bootstrap-touchspin-injected.input-group-prepend {
  left : 12px;
  position : relative;
}

.bootstrap-touchspin .bootstrap-touchspin-injected.input-group-append {
  right : 12px;
  position : relative;
}

.bootstrap-touchspin .bootstrap-touchspin-injected .bootstrap-touchspin-down, .bootstrap-touchspin .bootstrap-touchspin-injected .bootstrap-touchspin-up {
  padding : 0;
  min-width : 20px;
  min-height : 20px;
  border-radius : 0.357rem !important;
}

.bootstrap-touchspin .bootstrap-touchspin-injected .bootstrap-touchspin-down i, .bootstrap-touchspin .bootstrap-touchspin-injected .bootstrap-touchspin-down svg, .bootstrap-touchspin .bootstrap-touchspin-injected .bootstrap-touchspin-up i, .bootstrap-touchspin .bootstrap-touchspin-injected .bootstrap-touchspin-up svg {
  height : 0.8rem;
  width : 0.8rem;
  font-size : 0.8rem;
  position : relative;
  top : -1px;
}

.bootstrap-touchspin.disabled-touchspin .bootstrap-touchspin-down, .bootstrap-touchspin.disabled-touchspin .bootstrap-touchspin-up {
  background-color : rgba(34, 41, 47, 0.5) !important;
  cursor : default;
  opacity : 0.5;
}

.bootstrap-touchspin.input-group-lg {
  width : 9.375rem;
}

.bootstrap-touchspin.input-group-lg .touchspin.form-control {
  height : auto !important;
}

.bootstrap-touchspin.input-group-lg .bootstrap-touchspin-down, .bootstrap-touchspin.input-group-lg .bootstrap-touchspin-up {
  min-width : 24px;
  min-height : 24px;
}

.bootstrap-touchspin.input-group-lg .bootstrap-touchspin-down i, .bootstrap-touchspin.input-group-lg .bootstrap-touchspin-down svg, .bootstrap-touchspin.input-group-lg .bootstrap-touchspin-up i, .bootstrap-touchspin.input-group-lg .bootstrap-touchspin-up svg {
  height : 1rem;
  width : 1rem;
  font-size : 1rem;
  top : -3px;
}

.bootstrap-touchspin.input-group-sm {
  width : 6.25rem;
}

.bootstrap-touchspin.input-group-sm .touchspin.form-control {
  height : auto !important;
}

.bootstrap-touchspin.input-group-sm .bootstrap-touchspin-injected .bootstrap-touchspin-down, .bootstrap-touchspin.input-group-sm .bootstrap-touchspin-injected .bootstrap-touchspin-up {
  min-width : 16px;
  min-height : 16px;
}

.bootstrap-touchspin.input-group-sm .bootstrap-touchspin-injected .bootstrap-touchspin-down i, .bootstrap-touchspin.input-group-sm .bootstrap-touchspin-injected .bootstrap-touchspin-down svg, .bootstrap-touchspin.input-group-sm .bootstrap-touchspin-injected .bootstrap-touchspin-up i, .bootstrap-touchspin.input-group-sm .bootstrap-touchspin-injected .bootstrap-touchspin-up svg {
  height : 0.6rem;
  width : 0.6rem;
  font-size : 0.6rem;
}

.bootstrap-touchspin.input-group input[type='number'] {
  -moz-appearance : textfield;
}

.dark-layout .bootstrap-touchspin.input-group .form-control {
  background-color : #161D31;
}

.dark-layout .bootstrap-touchspin.disabled-touchspin .bootstrap-touchspin-injected .bootstrap-touchspin-down, .dark-layout .bootstrap-touchspin.disabled-touchspin .bootstrap-touchspin-injected .bootstrap-touchspin-up, .dark-layout .bootstrap-touchspin.disabled-touchspin .bootstrap-touchspin-injected .disabled-max-min {
  background-color : #B8C2CC !important;
  opacity : 1;
}

.dark-layout .bootstrap-touchspin .bootstrap-touchspin-injected .disabled-max-min {
  background-color : #B8C2CC !important;
  opacity : 1;
}
